import { makeVar as apolloMakeVar } from '@apollo/client'

export const makeVar = (initialValues) => {
  const makeVarInstance = apolloMakeVar(initialValues)
  const update = updater => {
    return makeVarInstance(
      updater(makeVarInstance())
    )
  }

  return {
    update,
    reactiveVar: makeVarInstance
  }
}
