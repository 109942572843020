export const USER_IMAGE = 'USER_IMAGE'
export const USER_COVER = 'USER_COVER'
export const USER_SPRITE = 'USER_SPRITE'
export const USER_VIDEO = 'USER_VIDEO'
export const USER_VIDEO_THUMBNAIL = 'USER_VIDEO_THUMBNAIL'
export const AUDIO = 'AUDIO'
export const PRESET = 'PRESET'
export const SCENE_IMAGE = 'SCENE_IMAGE'
export const TOKEN_IMAGE = 'TOKEN_IMAGE'
export const TOKEN_VIDEO = 'TOKEN_VIDEO'
export const MODEL_FILE = 'MODEL_FILE'
