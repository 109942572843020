import { linearGradient } from 'polished'
import React from 'react'
import styled from 'styled-components'

export const VARIANT_PRIMARY = 'VARIANT_PRIMARY'
export const VARIANT_SECONDARY = 'VARIANT_SECONDARY'
export const VARIANT_EPIC = 'VARIANT_EPIC'
export const VARIANT_LEGENDARY = 'VARIANT_LEGENDARY'

// linear-gradient(
// 280deg,#41faff 0%,#ec4eff 100%);

// linear-gradient(
// 167deg,#41faff 0%,#ec4eff 100%);

// linear-gradient(
// 280deg,#d24fff 0%,#70a3ff 100%);

const getGradientFromVariant = variant => {
  if (variant === VARIANT_PRIMARY) {
    return linearGradient({
      colorStops: [
        '#d24fff 0%',
        '#70a3ff 100%'
      ],
      toDirection: '280deg'
    })
  } else if (variant === VARIANT_SECONDARY) {
    return linearGradient({
      colorStops: [
        '#41ff61 0%',
        '#4ea5ff 100%'
      ],
      toDirection: '70deg'
    })
  } else if (variant === VARIANT_EPIC) {
    return linearGradient({
      colorStops: [
        '#41ff61 0%',
        '#4ea5ff 100%'
      ],
      toDirection: '70deg'
    })
  } else if (variant === VARIANT_LEGENDARY) {
    return linearGradient({
      colorStops: [
        '#ff5900 0%',
        '#ffb100 100%'
      ],
      toDirection: '70deg'
    })
  }
}

const Component = styled.span`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: break-spaces;
  display: inline-block;
  cursor: pointer;
  ${props => getGradientFromVariant(props.$variant)};
`

const GradientText = ({ variant = VARIANT_PRIMARY, children }) => {
  return (
    <Component $variant={variant}>
      {children}
    </Component>
  )
}

GradientText.VARIANT_PRIMARY = VARIANT_PRIMARY
GradientText.VARIANT_SECONDARY = VARIANT_SECONDARY
GradientText.VARIANT_EPIC = VARIANT_EPIC
GradientText.VARIANT_LEGENDARY = VARIANT_LEGENDARY

export default GradientText
