import { gql } from '@apollo/client'

import userFragment from '../fragments/User'

const userUpdateById = gql`
  mutation UserUpdateById ($input: InputUserUpdate!) {
    userUpdateById(input: $input) {
      ...UserFragment
    }
  }
  ${userFragment}
`

export default userUpdateById
