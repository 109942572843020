import { gql } from '@apollo/client'

import UserFragment from '../fragments/User'

const userByPublicAddress = gql`
  query userByPublicAddress (
    $publicAddress: String!
  ) {
    userByPublicAddress(
      publicAddress: $publicAddress
    ) {
      ...UserFragment
    }
  }
  ${UserFragment}
`

export default userByPublicAddress
