import styled from 'styled-components'

import { CONTAINER_WIDTH, MOBILE_SIDE_PADDING } from '../theme'

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  padding: 0 ${MOBILE_SIDE_PADDING}px;
  margin: 0 auto;
`

export default Container
