/**
 * The reason why this is not in wallet, is because it would re-render all components
 * listening to wallet which would be less efficient than just extracting it out
 */

import { makeVar } from '@apollo/client'

const initialValues = null

const balanceVar = makeVar(initialValues)
export default balanceVar
