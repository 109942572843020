import { Spinner } from '@triptcip/common'
import React from 'react'
import styled from 'styled-components'

import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '../theme'
import { MEDIA_QUERY_DESKTOP } from '../utils/media-query'

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);

  ${MEDIA_QUERY_DESKTOP} {
    min-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
  }
`

const PageLoader = () => {
  return (
    <Component>
      <Spinner
        variant={Spinner.VARIANT_PRIMARY}
        size={42}
      />
    </Component>
  )
}

export default PageLoader
