import _ from 'lodash'

import signFileUpload from '../apollo/mutations/signFileUpload'

const THROTTLE_CAP = 750

export const getSignedUrl = async (apolloClient, fileName, type) => {
  const signedUrlRes = await apolloClient.mutate({
    mutation: signFileUpload,
    variables: {
      fileName,
      type
    }
  })

  return signedUrlRes?.data?.signFileUpload
}

const xhrWithProgress = ({
  url,
  method = 'PUT',
  onProgress,
  file,
  headers = {}
}) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest()

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        resolve(xhr)
      } else {
        reject(xhr)
      }
    }
  }

  const handleProgress = (e) => {
    if (e.lengthComputable) {
      const percentage = (e.loaded / file.size) * 100
      onProgress(percentage)
    }
  }

  if (onProgress) {
    xhr.upload.addEventListener('progress', _.throttle(handleProgress, THROTTLE_CAP))
  }

  xhr.onerror = (error) => {
    reject(error || new Error('An error happened with uploading'))
  }

  xhr.open(method, url)

  Object.entries(headers).forEach(e => {
    const [key, value] = e
    xhr.setRequestHeader(key, value)
  })

  xhr.send(file)
})

export const uploadFile = async (apolloClient, file, type, onProgress, disableCache = false) => {
  const res = await getSignedUrl(apolloClient, file.name, type)

  const signedUrl = res?.signedUrl
  const url = res?.url

  if (!signedUrl || !url) {
    throw new Error('Unknown')
  }

  const oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

  let headers = {
    'cache-control': 'max-age=31536000',
    Expires: oneYearFromNow
  }

  if (disableCache) {
    headers = {}
  }

  await xhrWithProgress({
    url: signedUrl,
    method: 'PUT',
    onProgress,
    file,
    headers
  })

  return res
}
