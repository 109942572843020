import { useReactiveVar } from '@apollo/client'

import sessionVar from '../apollo/vars/session'

const getUserFromSession = session => {
  const user = session?.user

  if (!user) {
    return null
  }

  const id = user?.id
  const publicAddress = user?.publicAddress
  const image = user?.image
  const email = user?.email
  const username = user?.username
  const name = user?.name
  const bio = user?.bio
  const isAdmin = user?.isAdmin
  const isCreator = user?.isCreator
  const coverUri = user?.coverUri
  const hasObjectsWhitelist = user?.hasObjectsWhitelist
  const pinnedPresetNames = user?.pinnedPresetNames
  const features = user?.features || []
  const referralCode = user?.referralCode

  return {
    id,
    publicAddress,
    image,
    email,
    username,
    hasObjectsWhitelist,
    name,
    bio,
    isAdmin,
    isCreator,
    coverUri,
    pinnedPresetNames,
    features,
    referralCode
  }
}

const useSession = () => {
  const session = useReactiveVar(sessionVar.reactiveVar)
  const isLoggedIn = !!session.user
  const user = getUserFromSession(session)

  return {
    isLoggedIn,
    isInitialized: session.isInitialized,
    user
  }
}

export default useSession
