import Link from 'next/link'
import React from 'react'
import { XCircle } from 'react-feather'
import { Portal } from 'react-portal'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import styled from 'styled-components'

import { INSTAGRAM, TWITTER } from '../../config'
import { COLLECTIONS, HOME, LEARN } from '../../constants/Routes'
import useOnRouteChange from '../../hooks/useOnRouteChange'
import { FONT_FAMILY_SECONDARY, HEADER_HEIGHT } from '../../theme'
import { optimizedLogoUri } from '../../utils/images'
import { getInstagramUrl, getTwitterUrl } from '../../utils/social-media'
import ExternalLink from '../ExternalLink'

const menuItems = [{
  label: 'Objects',
  to: HOME
}, {
  label: 'Collections',
  to: COLLECTIONS
}, {
  label: 'Learn',
  to: LEARN
}]

const footerItems = [{
  label: 'Twitter',
  href: getTwitterUrl(TWITTER)
}, {
  label: 'Instagram',
  href: getInstagramUrl(INSTAGRAM)
}]

const Component = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  height: ${HEADER_HEIGHT}px;
`

const LogoWrapper = styled.div`
  padding: 1rem;
`

const Logo = styled.div`
`

const ButtonClose = styled.button`
  background: none transparent;
  align-self: flex-start;
  appearance: none;
  border: 0;
  margin-left: auto;
  padding: 1rem;
  outline: 0;
  margin-top: 1rem;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
  margin-left: .5rem;
  margin-bottom: 1rem;
`

const MenuItems = styled.div`
  a {
    font-family: ${FONT_FAMILY_SECONDARY};
    font-size: 2.25rem;
    line-height: 1;
    letter-spacing: -2px;
    font-weight: 700;
    padding: .5rem;
    display: block;
    color: black;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none !important;
    }
  }
`

const LogoImage = styled.img``

const Footer = styled.div`
  a {
    padding: .3rem;
    display: block;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    color: #757474;

    &:hover {
      text-decoration: none;
    }
  }
`

const MobileMenu = ({ isOpen, closeMenu }) => {
  useOnRouteChange(() => {
    closeMenu()
  })

  const handleLogoClick = () => {
    closeMenu()
  }

  const handleCloseClick = () => {
    closeMenu()
  }

  return (
    <Portal>
      {isOpen && (
        <Component>
          <RemoveScrollBar />
          <Header>
            <LogoWrapper>
              <Logo>
                <Link
                  href={HOME}
                  passHref
                >
                  <a>
                    <LogoImage
                      src={optimizedLogoUri}
                      width={67}
                      height={67}
                      alt='Triptcip'
                      onClick={handleLogoClick}
                    />
                  </a>
                </Link>
              </Logo>
            </LogoWrapper>
            <ButtonClose onClick={handleCloseClick}>
              <XCircle
                color='black'
                size={38}
              />
            </ButtonClose>
          </Header>
          <Content>
            <MenuItems>
              {menuItems.map((menuItem, i) => {
                if (menuItem.href) {
                  return (
                    <ExternalLink
                      key={i}
                      href={menuItem.href}
                    >
                      {menuItem.label}
                    </ExternalLink>
                  )
                }

                return (
                  <Link
                    key={i}
                    href={menuItem.to}
                  >
                    {menuItem.label}
                  </Link>
                )
              })}
            </MenuItems>
            <Footer>
              {footerItems.map((footerItem, i) => {
                return (
                  <ExternalLink
                    key={i}
                    href={footerItem.href}
                  >
                    {footerItem.label}
                  </ExternalLink>
                )
              })}
            </Footer>
          </Content>
        </Component>
      )}
    </Portal>
  )
}

export default MobileMenu
