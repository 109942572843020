// @ts-strict

import type { UseTransitionProps } from '@react-spring/core/dist/declarations/src/types'
import { animated, config, useTransition } from '@react-spring/web'
import type { ReactNode } from 'react'
import React from 'react'

const defaultOptions = {
  config: config.default,
  from: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  leave: {
    opacity: 0
  }
}

interface TransitionProps {
  shouldAnimate: boolean
  children: ReactNode
  style?: { [key: string]: React.CSSProperties }
  options?: UseTransitionProps
}

const Transition = ({
  shouldAnimate,
  children,
  style = {},
  options = defaultOptions
}: TransitionProps) => {
  const transition = useTransition(shouldAnimate, {
    ...options
  })

  return transition((props, item) => {
    return item && (
      <animated.div
        style={{
          ...props,
          ...style || {}
        }}
      >
        {children}
      </animated.div>
    )
  })
}

export default Transition
