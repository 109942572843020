import React from 'react'
import styled from 'styled-components'

import useSession from '../../hooks/useSession'
import useWallet from '../../hooks/useWallet'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import ButtonConnectWallet from './ButtonConnectWallet'
import PageHeaderAccountInfo from './PageHeaderAccountInfo'

const Component = styled.div`
  display: none;

  ${MEDIA_QUERY_DESKTOP} {
    display: flex;
    align-items: center;
  }
`

const getIsConnected = (session, wallet) => {
  if (!session.isLoggedIn) {
    return false
  }

  return wallet.isConnected
}

const PageHeaderControlsDesktop = () => {
  const session = useSession()
  const wallet = useWallet()

  const isLoggedInAndConnected = getIsConnected(session, wallet)

  if (!isLoggedInAndConnected) {
    return (
      <ButtonConnectWallet />
    )
  }

  return (
    <Component>
      <PageHeaderAccountInfo />
    </Component>
  )
}

const HeaderControlsDesktopWrapper = () => {
  return (
    <Component>
      <PageHeaderControlsDesktop />
    </Component>
  )
}

export default HeaderControlsDesktopWrapper
