import React from 'react'

import useConnectMetamask from '../../hooks/useConnectMetamask'
import ButtonSimple from '../ButtonSimple'

const ButtonConnectWallet = () => {
  const { isLoading, errors, retry, connect } = useConnectMetamask()

  const handleClick = async () => {
    if (errors?.length) {
      retry()
    }

    await connect()
  }

  return (
    <ButtonSimple
      isLoading={isLoading}
      onClick={handleClick}
    >
      Connect Wallet
    </ButtonSimple>
  )
}

export default ButtonConnectWallet
