import * as React from 'react'
import styled from 'styled-components'

// TODO: this styling could prob be a bit better
const Component = styled.button<{
  $textCenter?: boolean
}>`
  appearance: none;
  outline: 0;
  padding: 5px;
  margin: 0;
  border: 0;
  background-color: rgba(255, 255, 255, .05);
  color: rgba(255, 255, 255, .6);
  border-radius: 0;
  cursor: pointer;
  text-align: ${props => props.$textCenter ? 'center' : 'left'};
  font-size: 11px;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, .07);
  }
`

type Value = string | number

export interface ButtonShiftOption {
  label: string
  value: Value
}

export interface ButtonShiftProps<T> {
  value: T
  defaultValue?: T
  onChange: (value: T) => void
  options: ButtonShiftOption[]
  textCenter?: boolean
}

const ButtonShift = <T extends unknown>({
  value,
  defaultValue,
  onChange,
  options,
  textCenter = true
}: ButtonShiftProps<T>) => {
  const currentOption = options.find(option => option.value === value)

  const handleNextClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const pressedShift = e.shiftKey

    if (defaultValue !== undefined && pressedShift) {
      const option = options
        .find(o => o.value === defaultValue) as ButtonShiftOption

      return onChange(option.value as T)
    }

    const currentIndex = options.findIndex(o => o.value === value)
    let nextOption = options?.[currentIndex + 1]

    if (!nextOption) {
      nextOption = options[0]
    }

    onChange(nextOption.value as T)
  }

  const handleRightClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const currentIndex = options.findIndex(o => o.value === value)
    let prevOption = options?.[currentIndex - 1]

    if (!prevOption) {
      prevOption = options[options.length - 1]
    }

    onChange(prevOption.value as T)
  }

  return (
    <Component
      onClick={handleNextClick}
      onContextMenu={handleRightClick}
      $textCenter={textCenter}
    >
      {currentOption?.label}
    </Component>
  )
}

export default ButtonShift
