// language=glsl
import { fragmentShader } from './fragmentShader'

export const vertexShaderSource = `#version 300 es
// an attribute is an input (in) to a vertex shader.
// It will receive data from a buffer
in vec4 a_position;
// all shaders have a main function
void main() {
  // gl_Position is a special variable a vertex shader
  // is responsible for setting
  gl_Position = a_position;
}
`

// language=glsl
export const fragmentShaderSource = `#version 300 es
precision highp float;
${fragmentShader}
`

export function createShader(gl: WebGL2RenderingContext, type: number, source: string): WebGLShader {
  const shader = gl.createShader(type)

  if (shader === null) {
    throw new Error('Could not create shader')
  }

  gl.shaderSource(shader, source)
  gl.compileShader(shader)

  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS)

  if (!success) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(gl.getShaderInfoLog(shader))
    }

    gl.deleteShader(shader)
    throw new Error('Could not create shader')
  }

  return shader
}

export function createProgram(gl: WebGL2RenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram {
  const program = gl.createProgram()

  if (program === null) {
    throw new Error('Could not create program')
  }

  gl.attachShader(program, vertexShader)
  gl.attachShader(program, fragmentShader)
  gl.linkProgram(program)

  const success = gl.getProgramParameter(program, gl.LINK_STATUS)

  if (!success) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(gl.getProgramInfoLog(program))
    }

    gl.deleteProgram(program)
    throw new Error('Could not create programn')
  }

  return program
}
