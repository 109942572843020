// https://doc.instantreality.org/tools/color_calculator/

// language=glsl
export const fragmentShader = `
  uniform vec2 res;
  uniform float time;

  out vec4 outColor;

  // Constants
  const vec4 lnClr = vec4(0.2, 0.8, 0.2, 1.0);
  const float spd = 0.0;
  const float sz = 1.6;
  const float minOffsetSpread = 0.0;
  const float maxOffsetSpread = 0.1;
  const float minLineWidth = 0.03;
  const float maxLineWidth = 0.1;
  const float lineFrequency = 0.01;
  const float warpFrequency = 0.3;
  const float offsetFrequency = 3.0;
  const int lineCount = 9;
  const float offset = 5.3;

  // Precompute values
  const float cosFactor = 2.0;
  const float sinFactor = 6.28;
  const float warpOffset = 9.0;

  #define linePrimary(pos, hW, t) smoothstep(hW, 0.0, abs(pos - (t)))
  #define lineSecondary(pos, hW, t) smoothstep(hW + 0.005, hW, abs(pos - (t)))

  float noise(float t)
  {
    return (cos(t) + cos(t * 1.3 + 1.3) + cos(t * 1.4 + 1.4)) / 3.0;
  }

  float getY(float x, float horizontalFade, float offset)
  {
    return noise(x * lineFrequency + (time * offset) * spd) * horizontalFade * offset + offset;
  }

  void mainImage(out vec4 fragColor, in vec2 fragCoord)
  {
    vec2 uv = fragCoord.xy / res.xy;
    vec2 vp = (fragCoord - res.xy / 2.0) / res.x * 2.0 * sz;

    float horizontalFade = 1.0 - (cos(uv.x * cosFactor * (time * offset)) * 0.5 + 0.5);
    float verticalFade = 1.0 - (cos(uv.y * sinFactor) * 0.5 + 0.5);

    float warpNoiseX = noise(vp.x * warpFrequency + (time * offset) * spd * warpOffset);
    float warpNoiseY = noise(vp.y * warpFrequency + (time * offset) * spd * warpOffset + warpOffset);

    vp.y += warpNoiseX * (0.5 + horizontalFade);
    vp.x += warpNoiseY * horizontalFade;

    vec4 lines = vec4(0.);

    for (int l = 0; l < lineCount; l++)
    {
      float lineIndex = float(l) / float(lineCount);
      float tOffset = (time * offset) * spd;
      float pOffset = float(l) + vp.x * offsetFrequency * (0.9);
      float rand = noise(pOffset + tOffset) * 0.5 + 0.5;
      float hW = mix(minLineWidth, maxLineWidth, rand * horizontalFade) * 0.5;
      float offset = noise(pOffset + tOffset * (1.0 + lineIndex)) * mix(minOffsetSpread, maxOffsetSpread, horizontalFade);
      float linePosition = getY(vp.x, horizontalFade, offset);
      float line = linePrimary(linePosition, hW, vp.y) / 3.0 + lineSecondary(linePosition, hW * 0.15, vp.y);

      lines += line * lnClr * rand;
    }

    vec4 bg = vec4(0.);

    fragColor = mix(bg, bg, uv.x);
    fragColor *= verticalFade;
    fragColor += lines;
  }

  void main() {
    mainImage(outColor, gl_FragCoord.xy);
  }
`
