import React, { useState } from 'react'
import styled from 'styled-components'

import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import MobileMenu from './MobileMenu'
import PageHeaderMobileMenuButton from './PageHeaderMobileMenuButton'

const Component = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERY_DESKTOP} {
    display: none;
  }
`

const PageHeaderControlsMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMobileMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <Component>
      <PageHeaderMobileMenuButton onClick={handleMobileMenuClick} />
      <MobileMenu
        isOpen={isMenuOpen}
        closeMenu={closeMenu}
      />
    </Component>
  )
}

export default PageHeaderControlsMobile
