/* eslint-disable react/jsx-handler-names */
import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import styled from 'styled-components'

import sessionVar from '../../apollo/vars/session'
import { HOME, ADMIN, OBJECTS_ACTIVATE } from '../../constants/Routes'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useOnRouteChange from '../../hooks/useOnRouteChange'
import useSession from '../../hooks/useSession'
import { getUserProfileRoute } from '../../utils/routes'
import { clearToken } from '../../utils/token-store'
import ExternalLink from '../ExternalLink'

const HEADER_MENU_WIDTH = 180
const HEADER_MENU_WIDTH_CREATOR = 230

const Component = styled.div`
  position: absolute;
  right: 0;
  z-index: 200;
  color: #797979;
  border-radius: 0;
  font-weight: 600;
  background: black;
  width: 200px;
  border: 1px solid rgba(255, 255, 255, .1);
  margin-top: 1rem;
`

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: flex-end;
`

const MenuItemWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, .05);
  color: rgba(255, 255, 255, .5);
  transition: color 100ms;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  a {
    color: inherit;
    display: block;
    padding: 1rem;
    font-weight: inherit;

    &:active,
    &:focus {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none !important;
    }
  }
`

const MenuItemLabel = styled.div``
const MenuItemIcon = styled.div`
  margin-left: .4rem;
`

const MenuItem = ({ menuItem }) => {
  const icon = menuItem?.icon

  if (menuItem.href) {
    return (
      <ExternalLink
        href={menuItem.href}
      >
        <MenuItemContent>
          <MenuItemLabel>
            {menuItem.label}
          </MenuItemLabel>
          {icon && (
            <MenuItemIcon>
              {icon}
            </MenuItemIcon>
          )}
        </MenuItemContent>
      </ExternalLink>
    )
  }

  if (menuItem.to) {
    return (
      <Link
        href={menuItem.to}
      >
        <a>
          <MenuItemContent>
            <MenuItemLabel>
              {menuItem.label}
            </MenuItemLabel>
            {icon && (
              <MenuItemIcon>
                {icon}
              </MenuItemIcon>
            )}
          </MenuItemContent>
        </a>
      </Link>
    )
  }

  return (
    <MenuItemContent onClick={menuItem.onClick}>
      <MenuItemLabel>
        {menuItem.label}
      </MenuItemLabel>
      {icon && (
        <MenuItemIcon>
          {icon}
        </MenuItemIcon>
      )}
    </MenuItemContent>
  )
}

const PageHeaderMenu = ({ isOpen, closeMenu }) => {
  const session = useSession()
  const router = useRouter()
  const refComponent = useRef()

  useEffect(() => {
    if (!isOpen) {
      return
    }

    const handleKeyDown = event => {
      if (event.keyCode === 27) {
        closeMenu()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])

  useOnClickOutside(refComponent, () => {
    closeMenu()
  })

  useOnRouteChange(() => {
    closeMenu()
  })

  const handleClickDisconnect = useCallback(() => {
    clearToken()

    sessionVar.update(prevState => ({
      ...prevState,
      user: null
    }))

    Sentry.configureScope(scope => scope.setUser(null))
    router.push(HOME)
  }, [])

  const menuItems = useMemo(() => {
    const menu = []

    menu.push({
      to: getUserProfileRoute(session?.user),
      label: (
        <>
          View your profile
        </>
      )
    })

    menu.push({
      to: OBJECTS_ACTIVATE,
      label: (
        <>
          Activate
        </>
      )
    })

    if (session?.user?.isAdmin) {
      menu.push({
        to: ADMIN,
        label: (
          <>
            Manage content
          </>
        )
      })
    }

    menu.push({
      onClick: handleClickDisconnect,
      label: (
        <a>
          Logout
        </a>
      )
    })

    return menu
  }, [session.user])

  const isAdmin = session?.user?.isAdmin
  const isCreator = isAdmin || session?.user?.isCreator
  const width = isCreator ? HEADER_MENU_WIDTH_CREATOR : HEADER_MENU_WIDTH

  if (!isOpen) {
    return null
  }

  return (
    <Component
      $isCreator={isCreator}
      $width={width}
      ref={refComponent}
    >
      {menuItems.map((menuItem, i) => {
        return (
          <MenuItemWrapper key={i}>
            <MenuItem
              menuItem={menuItem}
            />
          </MenuItemWrapper>
        )
      })}
    </Component>
  )
}

export default PageHeaderMenu
