import { gql } from '@apollo/client'

import userFragment from '../fragments/User'

const userCreate = gql`
  mutation UserCreate ($input: InputUserCreate!) {
    userCreate(input: $input) {
      ...UserFragment
    }
  }
  ${userFragment}
`

export default userCreate
