import type { UserFeatures, UserFormattedPublic } from '@triptcip/api/src/types/user'

export const getHasUserAccess = (user: (UserFormattedPublic | undefined | null), feature: UserFeatures): boolean => {
  if (!user) {
    return false
  }

  if (user?.isAdmin === true) {
    return true
  }

  if ((user?.features || []).includes(feature)) {
    return true
  }

  return false
}
