export enum Tempo {
  TEMPO_1_64_BEATS = 'TEMPO_1_64_BEATS',
  TEMPO_1_32_BEATS = 'TEMPO_1_32_BEATS',
  TEMPO_1_16D_BEATS = 'TEMPO_1_16D_BEATS',
  TEMPO_1_16T_BEATS = 'TEMPO_1_16T_BEATS',
  TEMPO_1_16_BEATS = 'TEMPO_1_16_BEATS',
  TEMPO_1_8D_BEATS = 'TEMPO_1_8D_BEATS',
  TEMPO_1_8T_BEATS = 'TEMPO_1_8T_BEATS',
  TEMPO_1_8_BEATS = 'TEMPO_1_8_BEATS',
  TEMPO_1_4_BEATS = 'TEMPO_1_4_BEATS',
  TEMPO_1_2_BEATS = 'TEMPO_1_2_BEATS',
  TEMPO_1_BARS = 'TEMPO_1_BARS',
  TEMPO_2_BARS = 'TEMPO_2_BARS',
  TEMPO_4_BARS = 'TEMPO_4_BARS',
  TEMPO_8_BARS = 'TEMPO_8_BARS',
  TEMPO_16_BARS = 'TEMPO_16_BARS',
  TEMPO_32_BARS = 'TEMPO_32_BARS',
  TEMPO_64_BARS = 'TEMPO_64_BARS',
  TEMPO_128_BARS = 'TEMPO_128_BARS'
}

export const getTempoLabel = (t: Tempo): string => {
  switch (t) {
    case Tempo.TEMPO_128_BARS:
      return '128 bars'
    case Tempo.TEMPO_64_BARS:
      return '64 bars'
    case Tempo.TEMPO_32_BARS:
      return '32 bars'
    case Tempo.TEMPO_16_BARS:
      return '16 bars'
    case Tempo.TEMPO_8_BARS:
      return '8 bars'
    case Tempo.TEMPO_4_BARS:
      return '4 bars'
    case Tempo.TEMPO_2_BARS:
      return '2 bars'
    case Tempo.TEMPO_1_BARS:
      return '1 bar'
    case Tempo.TEMPO_1_2_BEATS:
      return '1/2'
    case Tempo.TEMPO_1_4_BEATS:
      return '1/4'
    case Tempo.TEMPO_1_8_BEATS:
      return '1/8'
    case Tempo.TEMPO_1_8D_BEATS:
      return '1/8D'
    case Tempo.TEMPO_1_8T_BEATS:
      return '1/8T'
    case Tempo.TEMPO_1_16_BEATS:
      return '1/16'
    case Tempo.TEMPO_1_16D_BEATS:
      return '1/16D'
    case Tempo.TEMPO_1_16T_BEATS:
      return '1/16T'
    case Tempo.TEMPO_1_32_BEATS:
      return '1/32'
    case Tempo.TEMPO_1_64_BEATS:
      return '1/64'
  }
}
