import React from 'react'

import { getTempoLabel, Tempo } from '../enums/tempo'
import type { ButtonShiftOption } from './ButtonShift'
import ButtonShift from './ButtonShift'

export enum TempoCollection {
  ESSENTIAL,
  FULL
}

const getTempoCollectionTempos = (tempoCollection: TempoCollection): Tempo[] => {
  if (tempoCollection === TempoCollection.ESSENTIAL) {
    return [
      Tempo.TEMPO_1_32_BEATS,
      Tempo.TEMPO_1_16_BEATS,
      Tempo.TEMPO_1_16T_BEATS,
      Tempo.TEMPO_1_8_BEATS,
      Tempo.TEMPO_1_8T_BEATS,
      Tempo.TEMPO_1_4_BEATS,
      Tempo.TEMPO_1_2_BEATS,
      Tempo.TEMPO_1_BARS,
      Tempo.TEMPO_2_BARS,
      Tempo.TEMPO_4_BARS,
      Tempo.TEMPO_8_BARS,
      Tempo.TEMPO_16_BARS,
      Tempo.TEMPO_32_BARS,
      Tempo.TEMPO_64_BARS
    ]
  }

  return Object.values(Tempo)
}

interface ButtonTempoProps {
  value: Tempo
  defaultValue?: Tempo
  onChange: (duration: Tempo) => void
  tempoCollection?: TempoCollection
}

const getOptions = (tempoCollection: TempoCollection): ButtonShiftOption[] => {
  const tempos = getTempoCollectionTempos(tempoCollection)

  return Object
    .values(tempos)
    .map(d => {
      return {
        value: d,
        label: getTempoLabel(d)
      }
    })
}

const ButtonTempo = ({
  value,
  defaultValue,
  onChange,
  tempoCollection = TempoCollection.ESSENTIAL
}: ButtonTempoProps) => {
  const options = getOptions(tempoCollection)

  return (
    <ButtonShift
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  )
}

export default ButtonTempo
