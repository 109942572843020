import {
  Web3Provider
} from '@ethersproject/providers'
import detectEthereumProvider from '@metamask/detect-provider'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import walletVar from '../apollo/vars/wallet'
import { initializeBalance } from '../utils/balance'
import { clearToken } from '../utils/token-store'
import useCryptoPrice from './useCryptoPrice'
import usePrevious from './usePrevious'
import useWallet from './useWallet'

const useWalletInitialize = () => {
  const wallet = useWallet()
  const ethUsdPrice = useCryptoPrice()
  const prevEthUsdPrice = usePrevious(ethUsdPrice)

  useEffect(() => {
    if (ethUsdPrice === prevEthUsdPrice) {
      return
    }

    walletVar.update(prevState => ({
      ...prevState,
      ethUsdPrice
    }))
  }, [
    ethUsdPrice
  ])

  useEffect(() => {
    if (wallet.isInitialized) {
      return
    }

    (async () => {
      try {
        const ethereum = await detectEthereumProvider({
          mustBeMetaMask: true,
          silent: true
        })

        if (!ethereum) {
          return walletVar.update(prevState => ({
            ...prevState,
            isInitialized: true,
            isInstalled: false,
            isConnected: false
          }))
        }

        // TODO: should be selected account...

        const [accounts, chainId] = await Promise.all([
          ethereum.request({
            method: 'eth_accounts'
          }),
          ethereum.request({
            method: 'eth_chainId'
          })
        ])

        // TODO: set Sentry user context

        const account = ethereum.selectedAddress
        const isConnected = !!account

        // eslint-disable-next-line no-console
        console.debug('useWalletInitialize', {
          accounts,
          account,
          chainId
        })

        const ethersProvider = new Web3Provider(ethereum)

        await initializeBalance(ethereum, account)

        walletVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          isInstalled: true,
          error: null,
          provider: ethersProvider,
          ethereum,
          isConnected,
          account,
          chainId: Number(chainId)
        }))
      } catch (error) {
        // TODO: report to Sentry
        Sentry.captureException(error)

        // eslint-disable-next-line no-console
        console.debug('useWalletInitialize error', error)

        walletVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          isConnected: false,
          error
        }))
      }
    })()
  }, [])

  // Setup window.ethereum event listeners
  useEffect(() => {
    if (!wallet.isInitialized) {
      return
    }

    const ethereum = wallet?.ethereum

    if (!ethereum) {
      return
    }

    const handleChainChanged = () => {
      window.location.reload()
    }

    const handleAccountsChanged = (accounts) => {
      const hasLoggedOut = !accounts?.length
      const hasSwitchedAccounts = !!wallet.account

      if (hasLoggedOut || hasSwitchedAccounts) {
        clearToken()
        return window.location.reload()
      }
    }

    // Emitted if Metamask can't submit RPC requests to any change
    const handleDisconnect = () => {
      window.location.reload()
    }

    if (window?.ethereum?.on) {
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('disconnect', handleDisconnect)
    }

    return () => {
      if (window?.ethereum?.removeListener) {
        window.ethereum.removeListener('chainChanged', handleChainChanged)
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged)
        window.ethereum.removeListener('disconnect', handleDisconnect)
      }
    }
  }, [
    wallet.isInitialized,
    wallet.account
  ])
}

export default useWalletInitialize
