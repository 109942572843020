import { gql } from '@apollo/client'

import UserFragment from '../fragments/User'

const session = gql`
  query Session {
    session {
      user {
        ...UserFragment
        features
        hasObjectsWhitelist
        referralCode
      }
    }
  }
  ${UserFragment}
`

export default session
