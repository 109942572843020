import { useState, useEffect } from 'react'

import { getEthereumFiatPrice } from '../utils/coingecko'

const useCryptoPrice = (refreshRate = 10000) => {
  const [converted, setConverted] = useState(null)

  useEffect(() => {
    (async () => {
      const update = async () => {
        const etherPriceUsd = await getEthereumFiatPrice()

        if (etherPriceUsd) {
          setConverted(etherPriceUsd)
        }
      }

      await update()

      setInterval(() => {
        update()
      }, refreshRate)
    })()
  }, [])

  return converted
}

export default useCryptoPrice
