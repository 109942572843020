import { buildURL } from 'react-imgix'

import * as config from '../config'

export const NAME = 'Triptcip'
export const DESCRIPTION = 'Audiovisual Creativity'

export const getOpenGraphImageUrl = (imageUri, width = 800, height = 600, quality = 80) => {
  if (!imageUri) {
    return ''
  }

  return buildURL(imageUri, {
    quality,
    width,
    height,
    fit: 'crop'
  })
}

export default {
  title: 'Triptcip ______ █████',
  defaultTitle: NAME,
  description: DESCRIPTION,
  canonical: config.URL,
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: config.URL,
    site_name: NAME,
    title: NAME,
    description: DESCRIPTION,
    images: [{
      url: `${config.URL}/images/open-graph.png?v=3`,
      alt: `${NAME}`,
      width: 1200,
      height: 630
    }]
  },
  twitter: {
    handle: `@${config.TWITTER}`,
    site: '@site',
    cardType: 'summary_large_image',
    domain: 'triptcip.com'
  }
}
