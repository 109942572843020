import { useEffect } from 'react'

const useEffectDelayed = (callback, delay, deps = []) => {
  useEffect(() => {
    setTimeout(() => {
      callback()
    }, delay)
  }, deps)
}

export default useEffectDelayed
