import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import * as config from '../../config'
import useBalance from '../../hooks/useBalance'
import useSession from '../../hooks/useSession'
import useWallet from '../../hooks/useWallet'
import { GRAY_900 } from '../../theme'
import { getShortBalanceLabel } from '../../utils/balance'
import { getShortPublicAddress } from '../../utils/formatting'
import StatusIndicator from '../StatusIndicator'
import UserImage from '../UserImage'
import PageHeaderMenu from './PageHeaderMenu'

const Component = styled.div`
  position: relative;
`

const AccountInfo = styled.button`
  padding: 6px;
  display: flex;
  align-items: center;
  background: none transparent;
  color: inherit;
  appearance: none;
  cursor: pointer;
  outline: 0;
  transition: background-color 80ms, border 80ms;
  border-color: rgba(255, 255, 255, .08);

  &:active,
  &:focus,
  &:hover {
    border-color: rgba(255, 255, 255, .4) !important;
  }

  &:active {
    border-color: rgba(255, 255, 255, .1);
  }

  &:focus {
    border-color: rgba(255, 255, 255, .1);
  }
`

const UserImageContainer = styled.div`
  position: relative;
`

const UserStatusIndicator = styled(StatusIndicator)`
  position: absolute;
  bottom: 0;
  left: -6px;
  border-width: 3px;
  border-style: solid;
  border-image: initial;
  border-color: ${GRAY_900};
  box-sizing: content-box;
`

const UserInfo = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
`

const UserBalance = styled.div`
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-bottom: .4rem;
  color: rgba(255, 255, 255, .8);
`

const UserAddress = styled.div`
  text-align: right;
  font-size: 12px;
  color: rgba(255, 255, 255, .6);
  line-height: 1;
`

const PageHeaderAccountInfo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const session = useSession()
  const wallet = useWallet()
  const balance = useBalance()
  const refAccountInfo = useRef()

  const chainId = wallet.chainId
  const isConnectedToSupportedChain = chainId === config.CHAIN_ID
  const statusIndicatorVariant = isConnectedToSupportedChain
    ? StatusIndicator.VARIANT_SUCCESS
    : StatusIndicator.VARIANT_WARN

  const balanceLabel = isConnectedToSupportedChain ? getShortBalanceLabel(balance) : 'Wrong network'

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <Component>
      <AccountInfo
        ref={refAccountInfo}
        onClick={handleClick}
      >
        <UserInfo>
          <UserBalance>
            {balanceLabel}
          </UserBalance>
          <UserAddress>
            {getShortPublicAddress(session.user.publicAddress)}
          </UserAddress>
        </UserInfo>
        <UserImageContainer>
          <UserImage user={session.user} />
          <UserStatusIndicator variant={statusIndicatorVariant} />
        </UserImageContainer>
      </AccountInfo>
      <PageHeaderMenu
        anchorEl={refAccountInfo.current}
        isOpen={isMenuOpen}
        closeMenu={closeMenu}
      />
    </Component>
  )
}

export default PageHeaderAccountInfo
