import * as React from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

const Component = styled.button`
  -webkit-appearance: none;
  outline: 0;
  cursor: pointer;
  background: none transparent;
  border: 0;
  padding: .5rem;
  margin: 0;
  opacity: .35;

  &:hover {
    opacity: 1;
  }
`

interface ButtonIconProps {
  children: ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ButtonIcon = ({ children, onClick }: ButtonIconProps) => {
  return (
    <Component onClick={onClick}>
      {children}
    </Component>
  )
}

export default ButtonIcon
