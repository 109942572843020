import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import userUpdateById from '../apollo/mutations/userUpdateById'

const useUserUpdateById = () => {
  const [mutation] = useMutation(userUpdateById)

  return useCallback(async ({ id, data }) => {
    const res = await mutation({
      variables: {
        input: {
          id,
          ...data
        }
      }
    })

    return res?.data?.userUpdateById
  }, [])
}

export default useUserUpdateById
