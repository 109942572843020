import React, { useCallback } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #bb64ff;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

// TODO: add React.GA's outboundLink, see uniswap / components / ExternalLink

const ExternalLink = ({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  ...props
}) => {
  const handleClick = useCallback((/* event */) => {
    // TODO: make me
    // if (target === '_blank' || event.ctrlKey || event.metaKey) {
    //   ReactGA.outboundLink({ label: href }, () => {
    //     // eslint-disable-next-line no-console
    //     console.debug('Fired outbound link event', href)
    //   })
    // } else {
    //   event.preventDefault()
    //   // send a ReactGA event and then trigger a location change
    //   ReactGA.outboundLink({ label: href }, () => {
    //     window.location.href = href
    //   })
    // }
  }, [href, target])

  return (
    <StyledLink
      target={target}
      rel={rel}
      href={href}
      onClick={handleClick}
      {...props}
    />
  )
}

export default ExternalLink
