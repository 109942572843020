import { useApolloClient } from '@apollo/client'
import { Web3Provider } from '@ethersproject/providers'
import detectEthereumProvider from '@metamask/detect-provider'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { useState } from 'react'

import sessionVar from '../apollo/vars/session'
import walletVar from '../apollo/vars/wallet'
import { getSignedMessage } from '../components/ButtonConnectMetaMask'
import { PROFILE } from '../constants/Routes'
import { getAccessToken, getNonce } from '../utils/auth'
import { initializeBalance } from '../utils/balance'
import { setToken } from '../utils/token-store'
import { isDefined } from '../utils/vars'
import { wait } from '../utils/wait'
import { BECAUSE_IS_NEW } from '../views/EditProfileView'
import { getSession } from './useSessionInitialize'

export const ERROR_NOT_INSTALLED = 'not-installed'
export const ERROR_NOT_ACTIVATED = 'not-activated'
export const ERROR_SIGN_FAILED = 'sign-failed'
export const ERROR_NOT_LOGGED_IN = 'not-logged-in'
export const ERROR_API_FAILED_TO_FETCH = 'Failed to fetch'
export const ERROR_API_BAD_REQUEST = 'Response not successful: Received status code 400'
export const ERROR_UNKNOWN = 'unknown'

// MetaMask error codes
const ERROR_CODE_SIGN_FAILED = 4001
const ERROR_CODE_FAIL = -32002

const initialState = {
  isLoading: false,
  errors: []
}

const whitelistLinks = [
  'whitelist'
]

const useConnectMetamask = () => {
  const [state, setState] = useState(initialState)
  const apolloClient = useApolloClient()
  const router = useRouter()
  const pathname = router.pathname
  const isWhitelistRoute = whitelistLinks.some(wl => pathname.includes(wl))

  const retry = () => {
    setState(initialState)
  }

  const connect = async () => {
    setState(prevState => ({
      ...prevState,
      isLoading: true
    }))

    try {
      const ethereum = await detectEthereumProvider({
        mustBeMetaMask: true
      })

      if (!ethereum) {
        window.alert('MetaMask was not found, is it installed?')

        return setState(prevState => ({
          ...prevState,
          isLoading: false,
          errors: [
            new Error(ERROR_NOT_INSTALLED)
          ]
        }))
      }

      const provider = new Web3Provider(ethereum)

      if (!isDefined(ethereum?._metamask?.isUnlocked)) {
        return window.alert('It looks like you have an older version of MetaMask, try upgrading Chrome or use a different browser.')
      }

      const isUnlocked = await ethereum._metamask.isUnlocked()

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      const account = ethereum.selectedAddress

      if (!isUnlocked) {
        await wait(1500)
      }

      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: eth_requestAccounts returned', accounts)

      // MetaMask was not activated
      if (!account) {
        // eslint-disable-next-line no-console
        console.log('ButtonConnectMetaMask: returned early due to no accounts', accounts)

        return setState(prevState => ({
          ...prevState,
          isLoading: false,
          errors: [
            new Error(ERROR_NOT_ACTIVATED)
          ]
        }))
      }

      const { nonce, isNew } = await getNonce(apolloClient, account)

      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: nonce', nonce)

      const signer = provider.getSigner()
      const message = getSignedMessage(nonce)

      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: message', message)

      const signature = await signer.signMessage(message)

      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: signature', signature)

      const accessToken = await getAccessToken(apolloClient, account, signature)

      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: accessToken', accessToken)

      // eslint-disable-next-line no-console
      console.log(`Account is: ${account}`)

      setToken(accessToken)

      walletVar.update(prevState => ({
        ...prevState,
        ethereum,
        provider,
        account,
        isConnected: true,
        error: null
      }))

      const session = await getSession(apolloClient)
      const user = session.user

      Sentry.setUser({
        id: user.id,
        username: user.username,
        email: user.email
      })

      sessionVar.update(prevState => {
        // eslint-disable-next-line no-console
        console.log('ButtonConnectMetaMask: full state', prevState)

        return {
          ...prevState,
          isInitialized: true,
          user: session.user
        }
      })

      setState(prevState => ({
        ...prevState,
        isLoading: false
      }))

      initializeBalance(ethereum, account).then(() => {
        // eslint-disable-next-line no-console
        console.log('ButtonConnectMetaMask: initializeBalance done')
      }).catch(e => {
        // eslint-disable-next-line no-console
        console.log('initializeBalance error: ' + e)

        Sentry.captureException(e)
      })

      if (!isWhitelistRoute && isNew) {
        await router.push(PROFILE + `?because=${BECAUSE_IS_NEW}`)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ButtonConnectMetaMask: got an error', error)

      // Sign has failed...
      if (error.code === ERROR_CODE_SIGN_FAILED) {
        return setState(prevState => ({
          ...prevState,
          isLoading: false,
          errors: [
            new Error(ERROR_SIGN_FAILED)
          ]
        }))

        // When not logged in to MetaMask
      } else if (error.code === ERROR_CODE_FAIL) {
        return setState(prevState => ({
          ...prevState,
          isLoading: false,
          errors: [
            new Error(ERROR_NOT_LOGGED_IN)
          ]
        }))
      } else {
        Sentry.captureException(error)

        const newError = new Error(ERROR_UNKNOWN)
        newError.info = error

        return setState(prevState => ({
          ...prevState,
          isLoading: false,
          errors: [error]
        }))
      }
    }
  }

  return {
    isLoading: state.isLoading,
    errors: state.errors,
    connect,
    retry
  }
}

export default useConnectMetamask
