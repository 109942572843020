import { Spinner } from '@triptcip/common'
import React from 'react'
import styled, { css } from 'styled-components'

const Content = styled.div`
  display: flex;
  align-items: center;
  will-change: opacity;
  transition: 200ms opacity;

  ${props => props.$isLoading && css`
    opacity: 0;
  `}
`

const Inner = styled.div`
  padding: 1rem 1.5rem;
`

const Component = styled.button`
  background: none transparent;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  ${props => {
    if (props.$variant === VARIANT_DEFAULT) {
      return css`
        border: 1px solid rgba(255, 255, 255, .5);
      `
    } else if (props.$variant === VARIANT_CRAZY) {
      return css`
        border: 0;
        padding: 0;
        margin: 0;
        background: linear-gradient(73deg, #00F3FC, #B300F2);

        ${Inner} {
          margin: 2px;
          background-color: black;
          display: block;
          border-radius: 99px;
        }

        ${Children} {
          background: linear-gradient(73deg, #00F3FC, #B300F2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          white-space: break-spaces;
          display: inline-block;
          cursor: pointer;
        }
      `
    } else if (props.$variant === VARIANT_PRIMARY) {
      return css`
        background-color: #9001FF;
        border: 0;
        color: white;
      `
    } else if (props.$variant === VARIANT_PRIMARY_BORDER) {
      return css`
        background-color: transparent;
        border: 1px solid #9001FF;
        color: #9001FF;
      `
    }
  }}

  ${props => props.$rounded && css`
    border-radius: 99px;
  `}
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`

const VARIANT_DEFAULT = 'default'
const VARIANT_PRIMARY = 'primary'
const VARIANT_PRIMARY_BORDER = 'primary-border'
const VARIANT_CRAZY = 'crazy'

const Children = styled.div``

const IconBefore = styled.div`
  margin-right: .5rem;
`

const ButtonSimple = React.forwardRef(({
  type,
  children,
  disabled,
  isLoading,
  onClick,
  rounded,
  iconBefore,
  variant = VARIANT_DEFAULT
}, ref) => {
  return (
    <Component
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={isLoading || disabled}
      $rounded={rounded}
      $variant={variant}
    >
      <Inner>
        {isLoading && (
          <Loader>
            <Spinner
              size={16}
              variant={Spinner.VARIANT_PRIMARY}
            />
          </Loader>
        )}
        <Content $isLoading={isLoading}>
          {iconBefore && (
            <IconBefore>
              {iconBefore}
            </IconBefore>
          )}
          <Children>
            {children}
          </Children>
        </Content>
      </Inner>
    </Component>
  )
})

ButtonSimple.VARIANT_DEFAULT = VARIANT_DEFAULT
ButtonSimple.VARIANT_PRIMARY = VARIANT_PRIMARY
ButtonSimple.VARIANT_PRIMARY_BORDER = VARIANT_PRIMARY_BORDER
ButtonSimple.VARIANT_CRAZY = VARIANT_CRAZY

export default ButtonSimple
