import * as Sentry from '@sentry/nextjs'
import { Formik, Form } from 'formik'
import React from 'react'
import slugify from 'slugify'
import styled from 'styled-components'
import * as Yup from 'yup'

import { URL } from '../config'
import { COLOR_ERROR } from '../theme'
import { getChangedValues } from '../utils/form'
import { BECAUSE_BID_NO_EMAIL, BECAUSE_CREATE_NO_EMAIL, BECAUSE_IS_NEW } from '../views/EditProfileView'
import Button from './Button'
import DelayedShow from './DelayedShow'
import EditProfileCoverUpload from './EditProfileCoverUpload'
import Errors from './Errors'
import FormElement from './Form/FormElement'
import InputText from './Form/InputText'
import Textarea from './Form/Textarea'
import Message from './Message'
import Toggle from './Toggle'

const BecauseMessageWrap = styled.div`
  margin-bottom: 2rem;
`

export const ErrorsContainer = styled.div`
  margin-bottom: 1rem;
  color: ${COLOR_ERROR};
`

const validationSchema = Yup.object({
  name: Yup
    .string()
    .required('Please enter your name')
    .max(80, 'Can\'t be more than 80 characters'),
  username: Yup
    .string()
    .required('Please enter your username')
    .max(40, 'Can\'t be more than 40 characters'),
  email: Yup
    .string()
    .required('Please enter your email')
    .email('Invalid email address'),
  bio: Yup
    .string()
    .max(500, 'Must be 500 characters or less')
})

const getErrorMessage = (error) => {
  switch (error?.message) {
    case 'username already in use':
      return (
        <>
          Username is already in use.
        </>
      )
  }

  Sentry.captureException(error)

  // TODO: maybe we also want a unique email?

  return (
    <>
      Oops, something bad happened but I'm not exactly sure what. Please try again.
    </>
  )
}

const EditProfileForm = ({
  initialValues,
  because,
  onSubmit,
  errors,
  isSubmitting,
  coverUri
}) => {
  const handleSubmit = async (values, actions) => {
    const changedValues = getChangedValues(values, initialValues)
    return onSubmit(changedValues, actions)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue, handleBlur }) => {
        const hasEmailAutoFocus = because === BECAUSE_CREATE_NO_EMAIL || because === BECAUSE_BID_NO_EMAIL

        const handleUsernameBlur = e => {
          const formatted = slugify(values.username, {
            lower: true,
            replacement: '-',
            strict: true
          })

          setFieldValue('username', formatted)
          handleBlur(e)
        }

        return (
          <Form>
            <FormElement
              label='Name'
              name='name'
            >
              <InputText
                autoFocus={because === BECAUSE_IS_NEW}
                name='name'
                maxLength={80}
                placeholder='Name'
              />
            </FormElement>
            <FormElement
              label='Username'
              name='username'
              footer={(
                <>
                  {URL}/{values.username}
                </>
              )}
            >
              <InputText
                maxLength={40}
                name='username'
                placeholder='Username'
                onBlur={handleUsernameBlur}
              />
            </FormElement>
            <FormElement
              label='Email'
              name='email'
              info={(
                <>
                  Receive email notifications when your auctions or bids get new updates.
                </>
              )}
            >
              <InputText
                autoFocus={hasEmailAutoFocus}
                name='email'
                placeholder='Email'
              />
            </FormElement>
            {because === BECAUSE_CREATE_NO_EMAIL && (
              <BecauseMessageWrap>
                <DelayedShow>
                  <Message variant={Message.VARIANT_WARNING}>
                    You must set an email address before you can create.
                  </Message>
                </DelayedShow>
              </BecauseMessageWrap>
            )}
            {because === BECAUSE_BID_NO_EMAIL && (
              <BecauseMessageWrap>
                <DelayedShow>
                  <Message variant={Message.VARIANT_WARNING}>
                    You must set an email address before you can place a bid.
                  </Message>
                </DelayedShow>
              </BecauseMessageWrap>
            )}
            <FormElement
              label='Cover photo'
              name='cover'
            >
              <EditProfileCoverUpload coverUri={coverUri} />
            </FormElement>
            <FormElement
              label='Bio'
              name='bio'
            >
              <Textarea
                maxLength={200}
                minRows={5}
                name='bio'
                placeholder='Bio'
              />
            </FormElement>
            <ErrorsContainer>
              <Errors
                errors={errors}
                hasErrorPadding={false}
                getErrorMessage={getErrorMessage}
              />
            </ErrorsContainer>
            <Toggle isOpen={dirty}>
              <Button
                type='submit'
                rounded
                isLoading={isSubmitting}
                variant={Button.VARIANT_PRIMARY}
              >
                Save changes
              </Button>
            </Toggle>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditProfileForm
