import copy from 'copy-to-clipboard'
import type { ReactNode } from 'react'
import * as React from 'react'
import { useRef, useState } from 'react'
import styled from 'styled-components'

const Component = styled.div`
  cursor: pointer;
`

const Label = styled.div``

interface CopyProps {
  children: ReactNode
  content: string
  labelLifeTime?: number
}

const Copy = ({ children, content, labelLifeTime = 500 }: CopyProps) => {
  const refUpdateTimeout = useRef<number | null>(null)
  const [hasCopied, setHasCopied] = useState<boolean>(false)

  const handleClick = () => {
    if (refUpdateTimeout.current) {
      window.clearTimeout(refUpdateTimeout.current)
    }

    setHasCopied(true)
    copy(content)

    refUpdateTimeout.current = window.setTimeout(() => {
      setHasCopied(false)
    }, labelLifeTime)
  }

  return (
    <Component onClick={handleClick}>
      {hasCopied ? (
        <Label>
          Copied to clipboard
        </Label>
      ) : children}
    </Component>
  )
}

export default Copy
