import * as React from 'react'
import styled from 'styled-components'

import * as theme from '../../../theme'

const PLAY_BUTTON_SCALE = .3

const IconPlay = styled.div`
  width: ${74 * PLAY_BUTTON_SCALE}px;
  height: ${74 * PLAY_BUTTON_SCALE}px;
  border-style: solid;
  box-sizing: border-box;
  border-width: ${37 * PLAY_BUTTON_SCALE}px 0px ${37 * PLAY_BUTTON_SCALE}px ${74 * PLAY_BUTTON_SCALE}px;
  border-color: transparent transparent transparent white;
`

const Component = styled.button`
  background: ${theme.COLOR_OBJECTS};
  border: 0;
  padding: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  margin: 0;
  font-family: ${theme.FONT_FAMILY_ALT};
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
`

const Label = styled.div`
  margin-left: 1rem;
`

interface ButtonPlayProps {
  onClick?: () => void
}

const ButtonPlay = ({ onClick }: ButtonPlayProps) => {
  return (
    <Component onClick={onClick}>
      <IconPlay />
      <Label>
        Play
      </Label>
    </Component>
  )
}

export default ButtonPlay
