/**
 * TODO: there are multiple version of the slider, and this only covers one
 * (the player slider, the other slider lives in VOID's parameters)
 */

import React from 'react'
import type { ReactSliderProps } from 'react-slider'
import ReactSlider from 'react-slider'
import styled from 'styled-components'

import { COLOR_BLUE } from '../theme'

const SLIDER_HEIGHT = 8
const THUMB_EXTRA_SCALE = 6

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: ${SLIDER_HEIGHT}px;
`

const StyledThumb = styled.div`
  height: ${SLIDER_HEIGHT + THUMB_EXTRA_SCALE}px;
  width: ${SLIDER_HEIGHT + THUMB_EXTRA_SCALE}px;
  background-color: white;
  border-radius: 100%;
  cursor: grab;
  top: -${THUMB_EXTRA_SCALE / 2}px;
`

const Thumb = (props: any) => <StyledThumb {...props} />

const StyledTrack = styled.div<{
  index: number
}>`
  top: 0;
  bottom: 0;
  background: ${props => (props.index === 1 ? '#242424' : COLOR_BLUE)};
  border-radius: 999px;
  cursor: pointer;
`

const Track = (props: any, state: any) => <StyledTrack {...props} index={state.index} />

const ProgressSlider = ({
  value,
  min,
  max,
  step,
  onChange
}: Omit<ReactSliderProps, 'renderTrack' | 'renderThumb'> & {
  variant?: string
  hasValueLabel?: boolean
}) => {
  return (
    <StyledSlider
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={onChange as any}
      renderTrack={Track}
      renderThumb={Thumb}
    />
  )
}

export default ProgressSlider
