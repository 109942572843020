import { Tempo } from '../enums/tempo'

const TRIPLET_MULTIPLIER = 0.6667
const DOTTED_MULTIPLIER = 1.5

export const secondsToMs = (seconds: number): number => {
  return seconds * 1000
}

export const msToSeconds = (ms: number): number => {
  return ms / 1000
}

export const getBarCount = ({
  start,
  end,
  bpm,
  beatsPerBar,
  digits = 1
}: {
  start: number
  end: number
  bpm: number
  beatsPerBar: number
  digits?: number
}) => {
  const barCount = (end - start) / (60 / bpm) / beatsPerBar
  return parseFloat(barCount.toString()).toFixed(digits)
}

export const getFloatFixedValue = (value: number): number => {
  return parseFloat(
    parseFloat(value.toString()).toFixed(2)
  )
}

export const getMsPerBeat = (bpm: number): number => {
  return 60000 / bpm
}

export const getTempoLevelInMs = ({
  bpm,
  beatsPerBar,
  speedLevel
}: {
  bpm: number
  beatsPerBar: number
  speedLevel: Tempo
}): number => {
  const msPerBeat = getMsPerBeat(bpm)

  switch (speedLevel) {
    case Tempo.TEMPO_128_BARS:
      return (beatsPerBar * msPerBeat) * 128
    case Tempo.TEMPO_64_BARS:
      return (beatsPerBar * msPerBeat) * 64
    case Tempo.TEMPO_32_BARS:
      return (beatsPerBar * msPerBeat) * 32
    case Tempo.TEMPO_16_BARS:
      return (beatsPerBar * msPerBeat) * 16
    case Tempo.TEMPO_8_BARS:
      return (beatsPerBar * msPerBeat) * 8
    case Tempo.TEMPO_4_BARS:
      return (beatsPerBar * msPerBeat) * 4
    case Tempo.TEMPO_2_BARS:
      return (beatsPerBar * msPerBeat) * 2
    case Tempo.TEMPO_1_BARS:
      return beatsPerBar * msPerBeat
    case Tempo.TEMPO_1_2_BEATS:
      return msPerBeat * 2
    case Tempo.TEMPO_1_4_BEATS:
      return msPerBeat
    case Tempo.TEMPO_1_8_BEATS:
      return msPerBeat * 0.5
    case Tempo.TEMPO_1_8T_BEATS:
      return (msPerBeat * 0.5) * TRIPLET_MULTIPLIER
    case Tempo.TEMPO_1_8D_BEATS:
      return (msPerBeat * 0.5) * DOTTED_MULTIPLIER
    case Tempo.TEMPO_1_16_BEATS:
      return msPerBeat * 0.25
    case Tempo.TEMPO_1_16T_BEATS:
      return (msPerBeat * 0.25) * TRIPLET_MULTIPLIER
    case Tempo.TEMPO_1_16D_BEATS:
      return (msPerBeat * 0.5) * DOTTED_MULTIPLIER
    case Tempo.TEMPO_1_32_BEATS:
      return msPerBeat * 0.125
    case Tempo.TEMPO_1_64_BEATS:
      return msPerBeat * 0.0625
    default:
      throw new Error(`Could not resolve getTempoLevelInMs, bpm: ${bpm}, beatsPerBar: ${beatsPerBar}, speedLevel: ${speedLevel}`)
  }
}
