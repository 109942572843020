import React, { useState } from 'react'

import useEffectDelayed from '../hooks/useEffectDelayed'
import Transition from './Transition'

const options = {
  from: {
    opacity: 0,
    transform: 'translateY(20px)'
  },
  enter: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  leave: {
    opacity: 0,
    transform: 'translateY(20px)'
  }
}

const DelayedShow = ({ delay = 500, children }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffectDelayed(() => {
    setIsVisible(true)
  }, delay)

  return (
    <Transition
      shouldAnimate={isVisible}
      options={options}
    >
      {children}
    </Transition>
  )
}

export default DelayedShow
