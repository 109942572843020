import { useRouter } from 'next/router'
import React, { useState } from 'react'
import styled from 'styled-components'

import sessionVar from '../apollo/vars/session'
import EditProfileForm from '../components/EditProfileForm'
import LoginGuard from '../components/LoginGuard'
import Page from '../components/Page'
import UserImageUpload from '../components/UserImageUpload'
import useSession from '../hooks/useSession'
import useUserUpdateById from '../hooks/useUserUpdateById'
import { getUserProfileRoute } from '../utils/routes'

const Form = styled.div`
  max-width: 32rem;
`

const UserImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

const getInitialValues = user => {
  return {
    name: user?.name || '',
    username: user?.username || '',
    email: user?.email || '',
    bio: user?.bio || ''
  }
}

export const BECAUSE_IS_NEW = 'is-new'
export const BECAUSE_CREATE_NO_EMAIL = 'create-no-email'
export const BECAUSE_BID_NO_EMAIL = 'bid-no-email'

const getTitle = because => {
  if (because === BECAUSE_IS_NEW) {
    return 'Create profile'
  }

  return 'Edit profile'
}

const EditProfileView = () => {
  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const router = useRouter()
  const session = useSession()
  const updateUser = useUserUpdateById()
  const initialValues = getInitialValues(session.user)

  const because = router?.query?.because
  const next = router?.query?.next

  const handleSubmit = async (values) => {
    setIsSubmitting(true)

    // Nothing changed...
    if (Object.keys(values).length === 0) {
      return false
    }

    setErrors([])

    try {
      const newUser = await updateUser({
        id: session.user.id,
        data: values
      })

      sessionVar.update(prevState => ({
        ...prevState,
        user: newUser
      }))

      setIsSubmitting(false)

      const nextRoute = next || getUserProfileRoute(newUser)
      router.push(nextRoute)
    } catch (error) {
      setIsSubmitting(false)
      setErrors([error])
    }
  }

  if (!session.user) {
    return null
  }

  const title = getTitle(because)

  return (
    <LoginGuard>
      <Page
        title={title}
        centered
      >
        <UserImageContainer>
          <UserImageUpload user={session.user} />
        </UserImageContainer>
        <Form>
          <EditProfileForm
            errors={errors}
            isSubmitting={isSubmitting}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            because={because}
            coverUri={session?.user?.coverUri}
          />
        </Form>
      </Page>
    </LoginGuard>
  )
}

export default EditProfileView
