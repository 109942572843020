import { useTransition, animated, config } from '@react-spring/web'
import { Spinner } from '@triptcip/common'
import React from 'react'
import styled from 'styled-components'

import { Z_MODAL_TRANSACTION_VIEW } from '../../theme'
import useTransactionView from './hooks/useTransactionView'

const Component = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_MODAL_TRANSACTION_VIEW};
  background-color: rgba(255, 255, 255, .98);
  color: rgba(0, 0, 0, .7);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`

const Title = styled.h1`
  margin-bottom: 2rem;
`
const Description = styled.div`
  margin-bottom: 4rem;
`

const Loader = styled.div``

const getAnimatedStyleComponent = props => {
  return {
    opacity: props.opacity,
    transform: props.scale.interpolate(s => `scale(${s})`)
  }
}

// TODO: what if it timesout?
const TransactionView = () => {
  const transactionView = useTransactionView()

  const transitions = useTransition(transactionView.isOpen, {
    from: {
      opacity: 0,
      scale: 0.95
    },
    enter: {
      opacity: 1,
      scale: 1.0
    },
    leave: {
      opacity: 0,
      scale: 1.1
    },
    config: config.default
  })

  return transitions((props, item) => item && (
    <Component
      style={getAnimatedStyleComponent(props)}
    >
      <Content>
        <Title>
          Brb, talking to Ethereum...
        </Title>
        <Description>
          This might take a while, we're verifying your transaction on the blockchain.
          <br />
          <strong>DO NOT close the browser</strong>, be patient.
        </Description>
        <Loader>
          <Spinner
            variant={Spinner.VARIANT_PRIMARY}
          />
        </Loader>
      </Content>
    </Component>
  ))
}

export default TransactionView
