import { gql } from '@apollo/client'

const signFileUpload = gql`
  mutation SignFileUpload (
    $fileName: String!
    $type: FileUploadType!
  ) {
    signFileUpload(
      fileName: $fileName
      type: $type
    ) {
      bucketFolder
      bucketName
      fileBucketLocation
      uniqueFileName
      signedUrl
      url
    }
  }
`

export default signFileUpload
