import { ErrorMessage } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { COLOR_RED, FONT_SIZE_S } from '../../theme'

const Component = styled.div`
  margin-bottom: 2rem;
`
export const Label = styled.label`
  display: block;
  font-weight: 600;
  color: rgba(255, 255, 255, .6);
  margin-bottom: 1rem;
`

const Info = styled.label`
  display: block;
  color: rgba(255, 255, 255, .4);
  font-size: ${FONT_SIZE_S};
  margin-bottom: .5rem;
`

const Footer = styled.label`
  display: block;
  color: rgba(255, 255, 255, .4);
  font-size: ${FONT_SIZE_S};
  margin-top: .5rem;
`

const Content = styled.div``

const StyledErrorMessage = styled.div`
  margin-top: 1rem;
  color: ${COLOR_RED};
`

const FormElement = ({ label, info, footer, name, children }) => {
  return (
    <Component>
      {label && (
        <Label>
          {label}
        </Label>
      )}
      {info && (
        <Info>
          {info}
        </Info>
      )}
      <Content>
        {children}
      </Content>
      {footer && (
        <Footer>
          {footer}
        </Footer>
      )}
      <StyledErrorMessage>
        <ErrorMessage name={name} />
      </StyledErrorMessage>
    </Component>
  )
}

export default FormElement
