import { gql } from '@apollo/client'

const UserFragment = gql`
  fragment UserFragment on User {
    id
    bio
    username
    email
    image
    isAdmin
    isCreator
    coverUri
    name
    links {
      link
      type
    }
    nonce
    publicAddress
    pinnedPresetNames
  }
`

export default UserFragment
