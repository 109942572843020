import React from 'react'

import useSession from '../hooks/useSession'
import useSessionInitialize from '../hooks/useSessionInitialize'
import useWalletInitialize from '../hooks/useWalletInitialize'
import NewPageFooter from './NewPageFooter'
import NewPageHeader from './NewPageHeader/NewPageHeader'
import PageLoader from './PageLoader'
import TransactionViewConsumer from './TransactionView/TransactionViewConsumer'

const Layout = ({
  children,
  hasFooter,
  hasHeader
}) => {
  const session = useSession()

  useSessionInitialize()
  useWalletInitialize()

  if (!session.isInitialized) {
    return (
      <PageLoader />
    )
  }

  return (
    <TransactionViewConsumer>
      {hasHeader && (
        <NewPageHeader />
      )}
      {children}
      {hasFooter && (
        <NewPageFooter />
      )}
    </TransactionViewConsumer>
  )
}

export default Layout
