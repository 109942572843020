import * as React from 'react'
import type { CSSProperties } from 'react'
import type { DefaultTheme, ThemedCssFunction } from 'styled-components'
import styled from 'styled-components'

export const TabsComponent = styled.div`
  display: flex;
`

export const TabComponent = styled.div<{
  $style: ThemedCssFunction<DefaultTheme>
}>`
  cursor: pointer;
  margin-right: 1rem;
  ${props => props.$style as any};
`

type TabId = string | number

export interface Tab {
  id: TabId
  label: string
}

interface TabsProps {
  tabs: Tab[]
  onChange: (tab: Tab) => void
  activeTabId: TabId
  styleContainer?: CSSProperties
  getStyleTab: (isActive: boolean) => ThemedCssFunction<DefaultTheme>
}

const Tabs = ({
  tabs,
  onChange,
  activeTabId,
  styleContainer,
  getStyleTab
}: TabsProps) => {
  const handleTabClick = (t: Tab) => () => {
    onChange(t)
  }

  return (
    <TabsComponent
      style={styleContainer}
    >
      {tabs.map(t => {
        const isActive = t.id === activeTabId
        const style = getStyleTab(isActive)

        return (
          <TabComponent
            key={t.id}
            onClick={handleTabClick(t)}
            $style={style}
          >
            {t.label}
          </TabComponent>
        )
      })}
    </TabsComponent>
  )
}

export default Tabs
