import { ApolloProvider } from '@apollo/client'
import type { NextSeoProps } from 'next-seo'
import { NextSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import apolloClient from '../apollo/apolloClient'
import GlobalStyle from '../components/GlobalStyle'
import Layout from '../components/Layout'
import { FACEBOOK_PIXEL_ID } from '../config'
import type { PageProps } from '../types/app'
import { pageView } from '../utils/ga'
import defaultSeo from '../utils/seo'
import { isDefined } from '../utils/vars'

import '../styles/fonts.css'
import 'sanitize.css'
import 'react-typist/dist/Typist.css'
import 'react-dropdown/style.css'

const getSeoData = (pageProps: PageProps): NextSeoProps => {
  const seoData = pageProps?.seoData

  const images =
    seoData?.image !== undefined ? [seoData.image] : defaultSeo.openGraph.images

  return {
    ...defaultSeo,
    title: seoData?.title || defaultSeo.title,
    description: seoData?.description || defaultSeo.description,
    openGraph: {
      ...defaultSeo.openGraph,
      title: seoData?.title || defaultSeo.openGraph.title,
      description: seoData?.description || defaultSeo.openGraph.description,
      images,
      ...(seoData?.openGraph || {})
    }
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      pageView(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    ;(async () => {
      if (!FACEBOOK_PIXEL_ID) {
        return null
      }

      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(FACEBOOK_PIXEL_ID)
          ReactPixel.pageView()

          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView()
          })
        })
    })()
  }, [])

  const seoData = getSeoData(pageProps)
  const hasFooter = isDefined(pageProps.hasFooter) ? pageProps.hasFooter : true

  const hasHeader = isDefined(pageProps.hasHeader) ? pageProps.hasHeader : true

  const bodyBackgroundColor = isDefined(pageProps?.bodyBackgroundColor)
    ? pageProps.bodyBackgroundColor
    : 'black'

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width,initial-scale=1' />
      </Head>
      <NextSeo {...seoData} />
      <GlobalStyle $bodyBackgroundColor={bodyBackgroundColor} />
      <ApolloProvider client={apolloClient}>
        <Layout hasHeader={hasHeader} hasFooter={hasFooter}>
          <Component {...pageProps} />
        </Layout>
      </ApolloProvider>
    </>
  )
}

export default App
