import React from 'react'
import styled from 'styled-components'

import { COLOR_OBJECTS } from '../theme'

const Component = styled.button`
  width: 100%;
  padding: .65rem 1rem;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  line-height: 1;
  font-size: 16px;
  font-weight: 800;
  color: #dbdbdb;
  background: none transparent;
  border: 2px solid ${COLOR_OBJECTS};
  border-radius: 0;
`

const ButtonCreate = () => {
  return (
    <Component>
      Projects
    </Component>
  )
}

export default ButtonCreate
