import React from 'react'
import Imgix from 'react-imgix'
import styled, { css } from 'styled-components'

import { HEADER_BG_COLOR } from '../theme'

const Placeholder = styled.div`
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;

  ${props => props.$bordered && css`
    border: 5px solid ${HEADER_BG_COLOR};
  `}

    // TODO: move to a var
  background: linear-gradient(194.41deg, #D709CF 10.22%, #5768F7 120.27%);
  border-radius: ${props => props.$size / 2}px;
`

const Component = styled(Imgix)`
  border-radius: ${props => props.$size / 2}px;

  ${props => props.$bordered && css`
    border: 5px solid ${HEADER_BG_COLOR};
  `}
`

const UserImage = ({ user, size = 42, bordered }) => {
  if (!user.image) {
    return (
      <Placeholder
        $size={size}
        $bordered={bordered}
      />
    )
  }

  return (
    <Component
      $bordered={bordered}
      $size={size}
      src={user.image}
      alt={user?.username}
      width={size}
      height={size}
    />
  )
}

export default UserImage
