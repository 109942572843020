export function resizeCanvasToDisplaySize(canvas: HTMLCanvasElement, multiplier: number = 1): boolean {
  const width  = canvas.clientWidth  * multiplier | 0
  const height = canvas.clientHeight * multiplier | 0

  if (canvas.width !== width ||  canvas.height !== height) {
    canvas.width  = width
    canvas.height = height
    return true
  }

  return false
}
