import type { ReactNode } from 'react'
import React, { useEffect, useRef } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

const Component = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
`

const Content = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  width: 720px;
`

interface ModelRenderProps {
  children: ReactNode
  onClose: () => void
}

const ModelRender = ({
  children,
  onClose
}: ModelRenderProps) => {
  const refContent = useRef()

  useEffect(() => {
    const handleDocumentKeydown = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handleDocumentKeydown)

    return () => {
      document.removeEventListener('keydown', handleDocumentKeydown)
    }
  }, [onClose])

  useClickAway(refContent, (e) => {
    const target = e.target as any
    const isContextMenu = target?.closest('[role="context-menu"]')

    if (isContextMenu) {
      return
    }

    onClose()
  })

  return (
    <Component>
      <Content ref={refContent}>
        {children}
      </Content>
    </Component>
  )
}

export default ModelRender
