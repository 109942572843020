import auth from '../apollo/mutations/auth'
import userCreate from '../apollo/mutations/userCreate'
import userByPublicAddress from '../apollo/queries/userByPublicAddress'

const getNonceFromExistingUser = async (apolloClient, publicAddress) => {
  const res = await apolloClient.query({
    query: userByPublicAddress,
    fetchPolicy: 'no-cache',
    variables: {
      publicAddress
    }
  })

  return res?.data?.userByPublicAddress?.nonce
}

const getNonceFromNewUser = async (apolloClient, publicAddress) => {
  const res = await apolloClient.mutate({
    mutation: userCreate,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        publicAddress
      }
    }
  })

  return res?.data?.userCreate?.nonce
}

export const getNonce = async (apolloClient, publicAddress) => {
  const nonceFromExistingUser = await getNonceFromExistingUser(apolloClient, publicAddress)

  if (nonceFromExistingUser) {
    return {
      isNew: false,
      nonce: nonceFromExistingUser
    }
  }

  return {
    isNew: true,
    nonce: await getNonceFromNewUser(apolloClient, publicAddress)
  }
}

export const getAccessToken = async (apolloClient, publicAddress, signature) => {
  const res = await apolloClient.mutate({
    mutation: auth,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        publicAddress,
        signature
      }
    }
  })

  return res?.data?.auth?.accessToken
}
