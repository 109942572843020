import React from 'react'
import styled, { css } from 'styled-components'

import Container from './Container'

const Component = styled.div`
  padding: 4rem 0;

  ${props => props.$centered && css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `}
`

const Title = styled.h1`
  text-align: center;
  margin-bottom: 4rem;
`

const Page = ({ title, children, centered }) => {
  return (
    <Component $centered={centered}>
      {title && (
        <Title>
          {title}
        </Title>
      )}
      <Container>
        {children}
      </Container>
    </Component>
  )
}

export default Page
