import { makeVar } from '../utils'

const initialState = {
  user: null,
  isInitialized: false,
  initializationError: null
}

const sessionVar = makeVar(initialState)
export default sessionVar
