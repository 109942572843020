import { Spinner } from '@triptcip/common'
import React from 'react'
import styled, { css } from 'styled-components'

import { GRADIENT_ALT, GRADIENT_DARK, GRADIENT_PRIMARY, GRADIENT_SECONDARY, GRAY_900 } from '../theme'

const VARIANT_DEFAULT = 'default'
const VARIANT_PRIMARY = 'primary'
const VARIANT_SECONDARY = 'secondary'
const VARIANT_ALT = 'alt'

const SIZE_SMALL = 'small'
const SIZE_NORMAL = 'normal'
const SIZE_LARGE = 'large'
const SIZE_XLARGE = 'xlarge'

const getBackground = props => {
  switch (props.$variant) {
    case VARIANT_PRIMARY:
      return GRADIENT_PRIMARY
    case VARIANT_DEFAULT:
      return GRADIENT_SECONDARY
    case VARIANT_SECONDARY:
      return GRADIENT_ALT
    case VARIANT_ALT:
      return GRADIENT_DARK
    default:
      return css`
        background: transparent
      `
  }
}

const getFontSize = props => {
  switch (props.$size) {
    case SIZE_XLARGE:
      return '1.4rem'
    case SIZE_SMALL:
      return '0.93rem'
    default:
      return '1rem'
  }
}

const getColor = (props) => {
  switch (props.$variant) {
    case VARIANT_PRIMARY:
    case VARIANT_SECONDARY:
      return 'white'
    case VARIANT_ALT:
      return '#B1B1B1'
    case VARIANT_DEFAULT:
      return GRAY_900
  }
}

const getPadding = props => {
  switch (props.$size) {
    case SIZE_SMALL:
      return '.6rem .8rem'
    case SIZE_NORMAL:
      return '1rem 1.5rem'
    case SIZE_LARGE:
      return '1.125rem 2rem'
    case SIZE_XLARGE:
      return '1.5rem 2.5rem'
  }
}

const getBorderRadius = props => {
  if (props.$rounded) {
    return '72px'
  }

  return '4px'
}

const getWidth = props => {
  if (props.$wide) {
    return '100%'
  }

  return 'auto'
}

const getHeight = props => {
  if (props.$high) {
    return '100%'
  }

  return 'auto'
}

const Component = styled.button`
  ${props => getBackground(props)}
  color: ${props => getColor(props)};
  border: 0;
  padding: ${props => getPadding(props)};
  border-radius: ${props => getBorderRadius(props)};
  outline: 0;
  position: relative;
  white-space: nowrap;
  font-weight: 600;
  transition: background 120ms ease, opacity 120ms ease, transform 120ms cubic-bezier(.2, 1, .3, 1);
  width: ${props => getWidth(props)};
  height: ${props => getHeight(props)};
  opacity: ${props => props.$opacity};
  font-size: ${props => getFontSize(props)};
  ${props => props.$style && props.$style};
  will-change: background, opacity, transform;

  ${props => {
    if (props.$size !== SIZE_SMALL) {
      return css`
        min-height: 3.125rem;
      `
    }
  }}

  &:active {
    transform: translateY(2px) !important;
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:disabled {
    cursor: not-allowed;

    &:active {
      transform: none !important;
    }

    &:hover {
      transform: none !important;
    }
  }
`

const IconBefore = styled.div`
  margin-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconAfter = styled.div`
  margin-left: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${props => props.$isLoading && css`
    opacity: 0;
  `}
`

const Inner = styled.div`
  line-height: 1;
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => getBackground(props)};
  border-radius: ${props => getBorderRadius(props)};
  z-index: 3;
`

const getSpinnerVariant = (spinnerVariant, variant) => {
  if (spinnerVariant) {
    return spinnerVariant
  }

  switch (variant) {
    case VARIANT_DEFAULT:
      return Spinner.VARIANT_PRIMARY
    case VARIANT_PRIMARY:
      return Spinner.VARIANT_LIGHT
  }
}

const Button = React.forwardRef((props, ref) => {
  const {
    onClick,
    children,
    variant = VARIANT_DEFAULT,
    size = SIZE_NORMAL,
    iconBefore,
    iconAfter,
    type = 'button',
    disabled,
    isLoading,
    rounded,
    wide = false,
    high = false,
    spinnerVariant,
    opacity = 1,
    style
  } = props

  return (
    <Component
      ref={ref}
      onClick={onClick}
      type={type}
      $variant={variant}
      disabled={disabled || isLoading}
      $rounded={rounded}
      $size={size}
      $wide={wide}
      $high={high}
      $opacity={opacity}
      $style={style}
    >
      {isLoading && (
        <Loader
          $variant={variant}
          $size={size}
          $rounded={rounded}
        >
          <Spinner variant={getSpinnerVariant(spinnerVariant, variant)} />
        </Loader>
      )}
      <Content $isLoading={isLoading}>
        {iconBefore && (
          <IconBefore>
            {iconBefore}
          </IconBefore>
        )}
        <Inner>
          {children}
        </Inner>
        {iconAfter && (
          <IconAfter>
            {iconAfter}
          </IconAfter>
        )}
      </Content>
    </Component>
  )
})

Button.VARIANT_DEFAULT = VARIANT_DEFAULT
Button.VARIANT_PRIMARY = VARIANT_PRIMARY
Button.VARIANT_SECONDARY = VARIANT_SECONDARY
Button.VARIANT_ALT = VARIANT_ALT

Button.SIZE_SMALL = SIZE_SMALL
Button.SIZE_NORMAL = SIZE_NORMAL
Button.SIZE_LARGE = SIZE_LARGE
Button.SIZE_XLARGE = SIZE_XLARGE

export default Button
