import React from 'react'
import styled, { css } from 'styled-components'

// TODO: this should move
export interface Option {
  label: string
  value: string | number
}

const Option = styled.button<{
  $isSelected: boolean
}>`
  padding: 8px 8px;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, .1);
  font-size: 12px;
  color: rgba(255, 255, 255, .85);
  opacity: .4;
  border: 0;
  font-family: inherit;
  line-height: 1;
  height: 40px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: .6;
  }

  ${props => {
    if (props.$isSelected) {
      return css`
          opacity: 1 !important;
          background: rgba(255, 255, 255, .15);
        `
    }
  }}
`

const Component = styled.div`
  display: flex;
`

interface OptionGroupProps<T> {
  options: Option[]
  value: T
  optionWidth: number
  onChange: (value: T) => void
}

const OptionGroup = <T extends unknown>({
  options,
  value,
  optionWidth,
  onChange
}: OptionGroupProps<T>) => {
  const handleOptionClick = (option: Option) => () => {
    onChange(option.value as T)
  }

  return (
    <Component>
      {options.map((o) => {
        const isSelected = o.value === value

        return (
          <Option
            style={{
              width: optionWidth
            }}
            key={o.value}
            $isSelected={isSelected}
            onClick={handleOptionClick(o)}
          >
            {o.label}
          </Option>
        )
      })}
    </Component>
  )
}

export default OptionGroup
