import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import { TWITTER, URL_DISCORD } from '../config'
import { PRIVACY_POLICY, SUBSCRIBE, TERMS_OF_SERVICE } from '../constants/Routes'
import { MEDIA_QUERY_DESKTOP } from '../utils/media-query'
import Container from './Container'
import GradientText from './GradientText'

const Component = styled.div`
  padding: 2rem 0;
  border-top: 1px solid rgba(255, 255, 255, .15);
`

const LogoContainer = styled.div`
  margin-bottom: 1.5rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-bottom: 0;
  }
`

const PrimaryLinks = styled.div`
  order: 2;

  ${MEDIA_QUERY_DESKTOP} {
    order: 1;
    display: flex;
    align-items: center;
    margin-left: 4rem;
  }
`

const PrimaryLink = styled.div`
  margin-bottom: 1rem;

  ${MEDIA_QUERY_DESKTOP} {
    margin-right: 2rem;
    margin-bottom: 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERY_DESKTOP} {
    flex-direction: row;
    align-items: flex-start;
  }
`

const SecondaryLink = styled.div`
  margin-bottom: 1rem;
`

const SecondaryLinks = styled.div`
  order: 1;

  ${MEDIA_QUERY_DESKTOP} {
    order: 2;
    text-align: right;
    margin-left: auto;
  }
`

const Logo = styled.img``

const LinkAnchor = styled.a`
  color: rgba(255, 255, 255, .5);

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const SecondaryLinkAnchor = styled.a`
  color: rgba(255, 255, 255, .5);

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const primaryLinks = [{
  label: 'Privacy',
  href: PRIVACY_POLICY
}, {
  label: 'Terms of service',
  href: TERMS_OF_SERVICE
}, {
  label: 'Pitch deck',
  href: 'https://docs.google.com/presentation/d/e/2PACX-1vSn40Tj5UL9R49VHRPyot8BRKjaCRlzc4yHoPgfbN5F8ymQ3Rzc4usk6O-qTx8Hyxw6DBdkjy1N_bYf/pub?start=false&loop=false&delayms=3000'
}, {
  label: 'Contact',
  href: 'mailto:hello@triptcip.com'
}]

const secondaryLinks = [{
  label: (
    <GradientText variant={GradientText.VARIANT_PRIMARY}>
      <strong>
        Join our community on Discord
      </strong>
    </GradientText>
  ),
  href: URL_DISCORD
}, {
  label: 'Follow us on Twitter',
  href: `https://twitter.com/${TWITTER}`
}, {
  label: 'Subscribe to our newsletter',
  href: SUBSCRIBE
}]

const NewPageFooter = () => {
  return (
    <Component>
      <Container>
        <Content>
          <LogoContainer>
            <Logo
              width={137}
              height={24}
              alt='Triptcip'
              src='/images/logo-text.svg'
            />
          </LogoContainer>
          <PrimaryLinks>
            {primaryLinks.map((link, index) => {
              return (
                <PrimaryLink key={index}>
                  <Link
                    prefetch={false}
                    href={link.href}
                    passHref
                  >
                    <LinkAnchor>
                      {link.label}
                    </LinkAnchor>
                  </Link>
                </PrimaryLink>
              )
            })}
          </PrimaryLinks>
          <SecondaryLinks>
            {secondaryLinks.map((link, index) => {
              return (
                <SecondaryLink key={index}>
                  <Link
                    prefetch={false}
                    href={link.href}
                    passHref
                  >
                    <SecondaryLinkAnchor>
                      {link.label}
                    </SecondaryLinkAnchor>
                  </Link>
                </SecondaryLink>
              )
            })}
          </SecondaryLinks>
        </Content>
      </Container>
    </Component>
  )
}

export default NewPageFooter
