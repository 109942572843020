import { makeVar } from '../utils'

const initialValues = {
  headerIsClosed: false
}

const layoutVar = makeVar(initialValues)

layoutVar.initialValues = {
  ...initialValues
}

export default layoutVar
