import { makeVar } from '../utils'

const initialValues = {
  isInitialized: false,
  isInstalled: null,
  provider: null,
  ethereum: null,
  isConnected: null,
  account: null,
  error: null,
  chainId: null,
  ethUsdPrice: null
}

const walletVar = makeVar(initialValues)
export default walletVar
