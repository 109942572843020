import React from 'react'
import { Menu } from 'react-feather'
import styled from 'styled-components'

const Component = styled.button`
  position: relative;
  appearance: none;
  border: 0;
  outline: 0;
  padding: .5rem;
  background: none transparent;
`

const PageHeaderMobileMenuButton = ({ onClick }) => {
  return (
    <Component onClick={onClick}>
      <Menu
        width={32}
        height={32}
        color='white'
      />
    </Component>
  )
}

export default PageHeaderMobileMenuButton
