export const INSTAGRAM = 'triptcip'
export const TWITTER = 'triptcip'

export const SUPPORT_EMAIL = 'hello@triptcip.com'

export const URL_IMAGES = 'https://triptcip.imgix.net'
export const URL_IMAGES_PRESETS = `${URL_IMAGES}/presets`
export const URL_IMAGE_SCENE_PLACEHOLDER = `${URL_IMAGES}/web-assets/scene-placeholder.png`

export const URL_AUDIO_TEST = process.env.NODE_ENV === 'development'
  ? 'https://sound.triptcip.com/transcoded/virtual-melt-dev.mp3'
  : 'https://sound.triptcip.com/transcoded/virtual-melt.mp3'

export const URL_APPLY_ARTIST = 'https://e73epwo0qoc.typeform.com/to/xLEhupPI'
export const URL_DISCORD = 'https://discord.gg/xTryJndpqx'
export const URL_TWITTER = `https://twitter.com/${TWITTER}`

export const URL = process.env.NEXT_PUBLIC_URL
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? ''
export const HOTJAR_SITE_ID = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const CHAIN_ID = Number(process.env.NEXT_PUBLIC_CHAIN_ID)
export const TRIPTCIP_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS
export const CONTRACT_ADDRESS_OBJECTS = '0x75524e4ca71Ca52D1a82a997bFB7F59f7e6541D4'

export const MIN_RESERVE_PRICE = 0.1
export const HAS_TRANSITIONS = false

// TODO: remove this line
