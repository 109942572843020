import * as React from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import * as theme from '../theme'

export const FormSectionTitle = styled.div`
  font-size: 14px;
  font-family: ${theme.FONT_FAMILY_ALT};
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  opacity: 1;
  cursor: pointer;
  appearance: none;
  background: none transparent;
  border: 0;
  margin: 0 0 6px 0;
  color: inherit;
  padding: 0;
`

const Component = styled.div`
  margin-bottom: 2rem;

  ${FormSectionTitle} {
    margin-bottom: 1rem;
  }
`

const Control = styled.div``
const Description = styled.div``

interface FormSectionProps {
  title: string | ReactNode
  children: ReactNode
  description?: string | ReactNode
}

const FormSection = ({ title, description, children }: FormSectionProps) => {
  return (
    <Component>
      <FormSectionTitle>
        {title}
      </FormSectionTitle>
      {description && (
        <Description>
          {description}
        </Description>
      )}
      <Control>
        {children}
      </Control>
    </Component>
  )
}

export default FormSection
