import { createGlobalStyle } from 'styled-components'

import {
  COLOR_OBJECTS,
  COLOR_TEXT,
  FONT_FAMILY_PRIMARY, FONT_FAMILY_SECONDARY,
  FONT_SIZE, FONT_SIZE_L, FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XL,
  FONT_SIZE_XXL,
  LINE_HEIGHT
} from '../theme'

interface IGlobalStyle {
  $bodyBackgroundColor: string
}

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  html {
    font: ${FONT_SIZE}px/${LINE_HEIGHT} ${FONT_FAMILY_PRIMARY};
    color: ${COLOR_TEXT};
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'ss01' on, 'cv01' on, 'cv03' on;
    font-display: fallback;
    font-variant: none;
  }

  body {
    overflow-y: scroll;
    background: ${props => props.$bodyBackgroundColor};
  }

  * {
    ::selection {
      color: white !important;
      background: ${COLOR_OBJECTS} !important;
    }

    ::-moz-selection {
      color: white !important;
      background: ${COLOR_OBJECTS} !important;
    }
  }

  @supports (font-variation-settings: normal) {
    html, input, textarea, button {
      font-family: 'Inter', sans-serif;
    }
  }

  strong {
    font-weight: 600;
  }

  button, input, textarea {
    font-family: ${FONT_FAMILY_PRIMARY};
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }

  button {
    user-select: none;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${COLOR_OBJECTS};

    &:hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }

  h1 {
    font: ${FONT_SIZE_XXL}/1.15 ${FONT_FAMILY_SECONDARY};
    font-weight: 600;
    letter-spacing: -2px;
  }

  h2 {
    font-size: ${FONT_SIZE_XL};
    font-weight: 600;
  }

  h3 {
    font-size: ${FONT_SIZE_L};
    font-weight: 600;
  }

  h4 {
    font-size: ${FONT_SIZE_M};
    font-weight: 600;
  }

  small {
    font-size: ${FONT_SIZE_S};
  }

  // Hide input text number controls
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox: Hide input text number controls
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export default GlobalStyle
