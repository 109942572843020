import { animated, config, useTransition } from '@react-spring/web'
import React from 'react'

const defaultOptions = {
  config: config.default,
  from: {
    opacity: 0
  },
  enter: {
    opacity: 1
  },
  leave: {
    opacity: 0
  }
}

const Transition = ({
  shouldAnimate,
  children,
  style = {},
  willChange = 'opacity',
  options = defaultOptions
}) => {
  const transition = useTransition(shouldAnimate, {
    ...options
  })

  return transition((props, item) => {
    return item && (
      <animated.div
        style={{
          ...props,
          ...style || {},
          willChange
        }}
      >
        {children}
      </animated.div>
    )
  })
}

export default Transition
