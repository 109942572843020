import { useApolloClient } from '@apollo/client'
import { Spinner } from '@triptcip/common'
import React, { useState } from 'react'
import { Upload } from 'react-feather'
import styled from 'styled-components'

import sessionVar from '../apollo/vars/session'
import { USER_IMAGE } from '../constants/FileUploadType'
import useUserUpdateById from '../hooks/useUserUpdateById'
import { COLOR_ERROR } from '../theme'
import { uploadFile } from '../utils/file-upload'
import Errors from './Errors'
import UserImage from './UserImage'

const UPLOAD_BTN_SIZE = 32
const USER_IMAGE_SIZE = 72

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Image = styled.div`
  position: relative;
`

const UploadButton = styled.button`
  position: absolute;
  top: 0;
  right: -${UPLOAD_BTN_SIZE / 2 * 0.5}px;
  background: none transparent;
  appearance: none;
  border: 0;
  padding: 0;
  outline: 0;
  z-index: 5;
  transition: 300ms opacity ease-out, transform 200ms;
  opacity: 1;

  &:disabled {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-20px);
  }
`

const UploadButtonLabel = styled.label`
  border-radius: ${UPLOAD_BTN_SIZE / 2}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  transition: 80ms transform ease, color 80ms ease;
  width: ${UPLOAD_BTN_SIZE}px;
  height: ${UPLOAD_BTN_SIZE}px;
  background-color: white;

  &:active {
    transform: translateY(2px);
  }
`

const InputFile = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0px;
  height: 0px;
`

const ErrorsContainer = styled.div`
  color: ${COLOR_ERROR};
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .2);
  border-radius: 100%;
  z-index: 1;
`

const MAX_FILE_SIZE = 1

const initialState = {
  isUploading: false,
  error: null
}

const getErrorMessage = error => {
  if (error.message === 'file-size') {
    return `Image file size is too large, please upload smaller than ${MAX_FILE_SIZE}mb`
  }

  return 'Oops, didn\'t upload. Please try again later.'
}

const UserImageUpload = ({ user }) => {
  const [state, setState] = useState(initialState)
  const apolloClient = useApolloClient()
  const updateUser = useUserUpdateById()

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files?.[0]

    if (!selectedFile || !selectedFile?.size) {
      return
    }

    const sizeInMB = selectedFile?.size / 1024 / 1024

    if (sizeInMB > MAX_FILE_SIZE) {
      return setState(prevState => ({
        ...prevState,
        error: new Error('file-size')
      }))
    }

    setState(prevState => ({
      ...prevState,
      error: null,
      isUploading: true
    }))

    try {
      const res = await uploadFile(apolloClient, selectedFile, USER_IMAGE)
      const newUserImageUrl = res.url

      await updateUser({
        id: user.id,
        data: {
          image: newUserImageUrl
        }
      })

      sessionVar.update(prevState => ({
        ...prevState,
        user: {
          ...prevState.user,
          image: newUserImageUrl
        }
      }))

      setState(prevState => ({
        ...prevState,
        isUploading: false
      }))
    } catch (e) {
      setState(prevState => ({
        ...prevState,
        isUploading: false,
        error: e
      }))
    }
  }

  const errors = state.error ? [state.error] : []

  return (
    <Component>
      <Image>
        <UploadButton
          disabled={state.isUploading}
          type='button'
        >
          <UploadButtonLabel htmlFor='upload-user-photo'>
            <Upload
              size={16}
            />
          </UploadButtonLabel>
          <InputFile
            id='upload-user-photo'
            type='file'
            accept='image/gif, image/jpeg, image/png'
            onChange={handleFileChange}
          />
        </UploadButton>
        <UserImage
          size={USER_IMAGE_SIZE}
          user={user}
        />
        {state.isUploading && (
          <Loader>
            <Spinner />
          </Loader>
        )}
      </Image>
      <ErrorsContainer>
        <Errors
          errors={errors}
          getErrorMessage={getErrorMessage}
        />
      </ErrorsContainer>
    </Component>
  )
}

export default UserImageUpload
