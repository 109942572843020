import { gql } from '@apollo/client'

const auth = gql`
  mutation Auth ($input: InputAuth!) {
    auth(input: $input) {
      accessToken
    }
  }
`

export default auth
