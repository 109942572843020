import * as Sentry from '@sentry/nextjs'
import React from 'react'
import styled from 'styled-components'

import useConnectMetamask, {
  ERROR_API_BAD_REQUEST,
  ERROR_API_FAILED_TO_FETCH,
  ERROR_NOT_INSTALLED,
  ERROR_SIGN_FAILED
} from '../hooks/useConnectMetamask'
import IconMetaMask from '../icons/IconMetaMask.svg'
import { COLOR_ERROR } from '../theme'
import Button from './Button'
import Errors from './Errors'
import ExternalLink from './ExternalLink'

export const getSignedMessage = nonce => {
  return `Sign this message to connect to Triptcip. ${nonce}`
}

const StyledIconMetaMask = styled(IconMetaMask)`
  margin-left: .2rem;
`

const ErrorInstallMetaMaskComponent = styled.div`
  text-align: center;
`

const LabelDownloadMetaMask = styled.small`
  display: block;
  margin-bottom: 1rem;
`

const BtnDownloadMetaMask = styled(Button)`
  margin-top: .5rem;
`

const ErrorInstallMetaMask = () => {
  return (
    <ErrorInstallMetaMaskComponent>
      <LabelDownloadMetaMask>
        Please install MetaMask first ;)
      </LabelDownloadMetaMask>
      <ExternalLink href='https://metamask.io'>
        <BtnDownloadMetaMask variant={Button.VARIANT_PRIMARY}>
          Download MetaMask
        </BtnDownloadMetaMask>
      </ExternalLink>
    </ErrorInstallMetaMaskComponent>
  )
}

const ErrorFailedComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ErrorFailedLabel = styled.div`
  margin-bottom: 1rem;
  max-width: 300px;
  text-align: center;
  color: ${COLOR_ERROR};
`

const ErrorUnknown = ({ retry }) => {
  return (
    <ErrorFailedComponent>
      <ErrorFailedLabel>
        Make sure in MetaMask you are logged in and approve Triptcip to connect.
      </ErrorFailedLabel>
      <Button
        size={Button.SIZE_SMALL}
        onClick={retry}
      >
        Retry
      </Button>
    </ErrorFailedComponent>
  )
}

const ErrorFailed = ({ retry }) => {
  return (
    <ErrorFailedComponent>
      <ErrorFailedLabel>
        Please sign the message in MetaMask.
      </ErrorFailedLabel>
      <Button
        size={Button.SIZE_SMALL}
        onClick={retry}
      >
        Retry
      </Button>
    </ErrorFailedComponent>
  )
}

const ErrorAPI = ({ retry }) => {
  return (
    <ErrorFailedComponent>
      <ErrorFailedLabel>
        The Triptcip server is having a hard time, please try again later.
      </ErrorFailedLabel>
      <Button
        size={Button.SIZE_SMALL}
        onClick={retry}
      >
        Retry
      </Button>
    </ErrorFailedComponent>
  )
}

const getErrorMessage = retry => (error) => {
  switch (error.message) {
    case ERROR_NOT_INSTALLED:
      return (
        <ErrorInstallMetaMask />
      )
    case ERROR_SIGN_FAILED:
      return (
        <ErrorFailed retry={retry} />
      )
    case ERROR_API_FAILED_TO_FETCH:
    case ERROR_API_BAD_REQUEST:
      return (
        <ErrorAPI retry={retry} />
      )
    default:
      Sentry.captureException(error)

      return (
        <ErrorUnknown
          retry={retry}
        />
      )
  }
}

const ButtonConnectMetaMask = () => {
  const { isLoading, retry, connect, errors } = useConnectMetamask()

  const handleClick = async () => {
    return connect()
  }

  const hasErrors = errors?.length > 0
  const btnOpacity = hasErrors ? 0.2 : 1

  return (
    <>
      <Button
        wide
        opacity={btnOpacity}
        disabled={hasErrors}
        isLoading={isLoading}
        iconAfter={(
          <StyledIconMetaMask
            width={39}
            height={39}
          />
        )}
        onClick={handleClick}
      >
        Connect with MetaMask
      </Button>
      <Errors
        errors={errors}
        getErrorMessage={getErrorMessage(retry)}
      />
    </>
  )
}

export default ButtonConnectMetaMask
