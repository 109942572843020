import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import session from '../apollo/queries/session'
import sessionVar from '../apollo/vars/session'
import { clearToken, getToken } from '../utils/token-store'

export const getSession = async (apolloClient) => {
  const res = await apolloClient.query({
    query: session,
    fetchPolicy: 'no-cache'
  })

  return res?.data?.session
}

const getIsUnauthenticatedError = e => {
  return e
    ?.networkError
    ?.result
    ?.errors
    ?.[0]
    ?.extensions
    ?.code === 'UNAUTHENTICATED'
}

const useSessionInitialize = () => {
  const apolloClient = useApolloClient()

  useEffect(() => {
    (async () => {
      const token = getToken()

      if (!token) {
        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true
        }))
      }

      try {
        const session = await getSession(apolloClient)
        const user = session.user

        Sentry.setUser({
          id: user.id,
          username: user.username,
          email: user.email
        })

        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          user: session.user
        }))
      } catch (e) {
        const isUnauthenticatedError = getIsUnauthenticatedError(e)

        // This situation happens when the token was invalid, we wanna clear the token
        if (isUnauthenticatedError) {
          clearToken()
        }

        Sentry.captureException(e)

        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          initializationError: e
        }))
      }
    })()
  }, [])
}

export default useSessionInitialize
