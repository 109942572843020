import React, { useState } from 'react'
import styled from 'styled-components'

import type { MenuItemType , MenuItemFn } from './Menu'
import Menu from './Menu'

const Component = styled.div``

interface ContextMenuState {
  mouseX?: number
  mouseY?: number
}

const initialState: ContextMenuState = {
  mouseX: undefined,
  mouseY: undefined
}

interface InjectedProps {
  isOpen: boolean
}

interface ContextMenuProps {
  children: (props: InjectedProps) => React.ReactNode
  menuItems: (MenuItemType[] | MenuItemFn)
}

// TODO: on touch device, long hold, show the menu...
const ContextMenu = ({ children, menuItems }: ContextMenuProps) => {
  const [state, setState] = useState<ContextMenuState>(initialState)

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setState(
      (): ContextMenuState => ({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4
      })
    )
  }

  const handleClose = () => {
    setState(initialState)
  }

  const isOpen = state.mouseY !== undefined

  return (
    <Component onContextMenu={handleContextMenu}>
      {children({ isOpen })}
      {isOpen && (
        <Menu
          menuItems={menuItems}
          x={state.mouseX as number}
          y={state.mouseY as number}
          onClose={handleClose}
        />
      )}
    </Component>
  )
}

export default ContextMenu
