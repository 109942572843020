import Portal from '@reach/portal'
import type { ReactNode } from 'react'
import React, { useRef } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import MenuItem from './MenuItem'

const Component = styled.div`
  position: fixed;
  background: #232323;
  font-size: 11px;
  z-index: 999999999;
  min-width: 140px;
`

export interface MenuItemType {
  isLoading?: boolean
  onClick: ({ onClose }: { onClose: () => void }) => () => void
  iconBefore?: ReactNode
  label: string
}

interface MenuItemFnProps {
  onClose: () => void
}

export type MenuItemFn = ({ onClose }: MenuItemFnProps) => ReactNode

interface MenuProps {
  x: number
  y: number
  menuItems: MenuItemType[] | MenuItemFn
  onClose: () => void
}

const Menu = ({ menuItems, x, y, onClose }: MenuProps) => {
  const refComponent = useRef(null)

  useClickAway(refComponent, () => {
    onClose()
  })

  const isMenuItemsComponent = typeof menuItems === 'function'

  return (
    <Portal>
      <Component
        role='context-menu'
        ref={refComponent}
        style={{
          top: y,
          left: x
        }}
      >
        {isMenuItemsComponent ? (
          <>
            {menuItems({
              onClose
            })}
          </>
        ) : (
          <>
            {menuItems.map((menuItem, index) => {
              const clickHandler = menuItem.isLoading
                ? // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {}
                : menuItem.onClick({
                  onClose
                })

              return (
                <MenuItem
                  key={index}
                  isLoading={menuItem.isLoading}
                  onClick={clickHandler}
                  iconBefore={menuItem.iconBefore}
                >
                  {menuItem.label}
                </MenuItem>
              )
            })}
          </>
        )}
      </Component>
    </Portal>
  )
}

export default Menu
