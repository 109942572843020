// @ts-strict

import { vertexShaderSource, fragmentShaderSource, createShader, createProgram } from './shaders'
import { resizeCanvasToDisplaySize } from './utils'

interface Options {
  canvas: HTMLCanvasElement
  width: number
  height: number
}

class AmazingLoaderRenderer {
  private canvas: HTMLCanvasElement
  private width: number
  private height: number
  private gl: WebGL2RenderingContext
  private raf?: number = undefined

  constructor (options: Options) {
    this.canvas = options.canvas
    this.width = options.width
    this.height = options.height

    const gl = this.canvas.getContext('webgl2', {
      powerPreference: 'high-performance',
      alpha: false,
      antialias: true,
      stencil: false,
      preserveDrawingBuffer: false,
      depth: false,
      failIfMajorPerformanceCaveat: true,
      desynchronized: true,
      premultipliedAlpha: true
    })

    if (!gl) {
      throw new Error('Not supported')
    }

    this.gl = gl
    this.initialize()
  }

  initialize = () => {
    const { gl, width, height, canvas } = this

    gl.viewport(0, 0, width, height)

    const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)
    const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)
    const program: WebGLProgram = createProgram(gl, vertexShader, fragmentShader)

    const positionAttributeLocation = gl.getAttribLocation(program, 'a_position')

    const resolutionLocation = gl.getUniformLocation(program, 'res')
    const mouseLocation = gl.getUniformLocation(program, 'mouse')
    const timeLocation = gl.getUniformLocation(program, 'time')

    const vao = gl.createVertexArray()

    // and make it the one we're currently working with
    gl.bindVertexArray(vao)

    // Create a buffer to put three 2d clip space points in
    const positionBuffer = gl.createBuffer()

    // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)

    // fill it with a 2 triangles that cover clip space
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
      -1, -1,  // first triangle
      1, -1,
      -1,  1,
      -1,  1,  // second triangle
      1, -1,
      1,  1
    ]), gl.STATIC_DRAW)

    // Turn on the attribute
    gl.enableVertexAttribArray(positionAttributeLocation)

    // Tell the attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    gl.vertexAttribPointer(
      positionAttributeLocation,
      2,          // 2 components per iteration
      gl.FLOAT,   // the data is 32bit floats
      false,      // don't normalize the data
      0,          // 0 = move forward size * sizeof(type) each iteration to get the next position
      0,          // start at the beginning of the buffer
    )

    let mouseX = 0
    let mouseY = 0

    const setMousePos = (e: MouseEvent) => {
      const rect = this.canvas.getBoundingClientRect()
      mouseX = e.clientX - rect.left
      mouseY = rect.height - (e.clientY - rect.top) - 1  // bottom is 0 in WebGL
    }

    canvas.addEventListener('mousemove', setMousePos)

    let then = 0
    let time = 0

    const render = (now: number) => {
      now *= 0.001  // convert to seconds
      const elapsedTime = Math.min(now - then, 0.1)
      time += elapsedTime
      then = now

      resizeCanvasToDisplaySize(gl.canvas)

      // Tell WebGL how to convert from clip space to pixels
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

      // Tell it to use our program (pair of shaders)
      gl.useProgram(program)

      // Bind the attribute/buffer set we want.
      gl.bindVertexArray(vao)

      gl.uniform2f(resolutionLocation, gl.canvas.width, gl.canvas.height)
      gl.uniform2f(mouseLocation, mouseX, mouseY)
      gl.uniform1f(timeLocation, time)

      gl.drawArrays(
        gl.TRIANGLES,
        0,     // offset
        6,     // num vertices to process
      )

      requestAnimationFrame(render)
    }

    render(0)
  }

  kill = () => {
    const { raf, gl } = this

    if (raf) {
      window.cancelAnimationFrame(raf)
    }

    if (gl) {
      gl.getExtension('WEBGL_lose_context')?.loseContext()
    }
  }
}

export default AmazingLoaderRenderer
