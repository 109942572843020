import { darken } from 'polished'
import * as React from 'react'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import * as theme from '../theme'

export enum ButtonVariant {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY'
}

const generateButtonVariant = (backgroundColor: string) => {
  return css`
    background: ${backgroundColor};
    color: #DDDDDD;

    &:hover {
      background: ${darken(0.075, backgroundColor)};
    }
  `
}

const Component = styled.button<{
  $variant: ButtonVariant
}>`
  -webkit-appearance: none;
  outline: 0;
  padding: .5rem .75rem;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;

  ${props => {
    switch (props.$variant) {
      case ButtonVariant.PRIMARY:
        return generateButtonVariant(theme.COLOR_OBJECTS)
      case ButtonVariant.DEFAULT:
        return generateButtonVariant('#2a2a2a')
    }
  }}
`

const IconBefore = styled.div`
  margin-right: .5rem;
`

const Content = styled.div`
  white-space: nowrap;
`

interface ButtonProps {
  iconBefore?: ReactNode
  children?: ReactNode
  onClick: () => void
  type?: 'button' | 'submit' | 'reset'
  variant?: ButtonVariant
}

const Button = ({
  iconBefore,
  onClick,
  children,
  type = 'button',
  variant = ButtonVariant.DEFAULT
}: ButtonProps) => {
  return (
    <Component
      $variant={variant}
      onClick={onClick}
      type={type}
    >
      {iconBefore && (
        <IconBefore>
          {iconBefore}
        </IconBefore>
      )}
      <Content>
        {children}
      </Content>
    </Component>
  )
}

export default Button
