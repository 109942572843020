// Colors
import { css } from 'styled-components'

export const COLOR_BLUE = '#5546FF'
export const COLOR_PURPLE = '#7A05D6'
export const COLOR_OBJECTS = '#8E0AFF'
export const COLOR_RED = '#f1113c'

// Fonts
export const FONT_FAMILY_PRIMARY = 'Inter, sans-serif'
export const FONT_FAMILY_ALT = '"Mortend", sans-serif'

export const scrollbarStyling = css`
  ::-webkit-scrollbar
  {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track
  {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.2);
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.15);
  }
`
