import React from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import { Spinner } from '@triptcip/common'

const Component = styled.div`
  cursor: pointer;
  user-select: none;
  padding: 6px 6px;
  opacity: .5;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .25);
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

const IconBefore = styled.div`
  margin-left: 0.5rem;
`

const Inner = styled.div``

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: not-allowed;
`

interface MenuItemProps {
  children: ReactNode
  onClick: () => void
  iconBefore?: ReactNode
  isLoading?: boolean
}

const MenuItem = ({
  children,
  iconBefore,
  isLoading,
  onClick
}: MenuItemProps) => {
  return (
    <Component onClick={onClick}>
      <Content>
        {iconBefore && (
          <IconBefore>
            {iconBefore}
          </IconBefore>
        )}
        <Inner>
          {children}
        </Inner>
        {isLoading && (
          <Loader>
            <Spinner
              size={16}
              variant={Spinner.VARIANT_PRIMARY}
            />
          </Loader>
        )}
      </Content>
    </Component>
  )
}

export default MenuItem
