import { useState } from 'react'

const useTransactionViewContextProvider = () => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    setIsOpen,
    isOpen
  }
}

export default useTransactionViewContextProvider
