import React from 'react'

import { LOGIN } from '../constants/Routes'
import useSession from '../hooks/useSession'
import Redirect from './Redirect'

const LoginGuard = ({ children }) => {
  const session = useSession()

  if (!session.isInitialized) {
    return
  }

  if (!session.isLoggedIn) {
    return (
      <Redirect href={LOGIN} />
    )
  }

  return children
}

export default LoginGuard
