export const HOME = '/'
export const TERMS_OF_SERVICE = '/terms'
export const PRIVACY_POLICY = '/privacy'
export const NOT_FOUND = '/404'
export const PROFILE = '/profile'
// TODO: remove me -> export const INVITE_USER = '/admin/invite-user'
export const ADMIN_CATEGORIES = '/admin/categories'
export const ADMIN = '/admin'
export const ADMIN_CATEGORY = '/admin/category'
export const ADMIN_TOKENS = '/admin/tokens'
export const ADMIN_TOKEN = '/admin/token'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_USERS_WHITELISTED = '/admin/whitelisted'
export const ADMIN_USER = '/admin/user'
export const ADMIN_USER_PROJECTS = '/admin/user/[userId]/projects'
export const PROJECTS = '/projects'
export const PROJECTS_LOOPS = '/projects/loops'
export const PROJECTS_CREATE = '/projects/create'
export const PROJECTS_CREATE_PROJECT = '/projects/create/project'
export const PROJECTS_CREATE_SCENE = '/projects/create/scene'
export const PROJECTS_CREATE_LOOP = '/projects/create/loop'
export const PROJECT = '/projects/[projectId]'
export const PROJECT_MINT = '/projects/[projectId]/mint'
export const PROJECT_LIST = '/projects/[projectId]/list'
export const LOGIN = '/login'
export const SUBSCRIBE = '/subscribe'
export const OBJECTS_MINT = '/objects/mint'
export const OBJECTS_MINT_DONE = '/objects/mint/done'
export const OBJECTS_ACTIVATE = '/objects/activate'

export const COLLECTIONS = '/collections'

export const COLLECTION = '/collections/[slug]'

export const OBJECTS = '/objects'
export const LEARN = '/learn'
