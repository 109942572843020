import { useRouter } from 'next/router'
import { useEffect } from 'react'

import usePrevious from './usePrevious'

const useOnRouteChange = (onRouteChange, deps = []) => {
  const router = useRouter()
  const currentPathname = router.pathname
  const prevPathname = usePrevious(router.pathname)

  useEffect(() => {
    if (!currentPathname || !prevPathname) {
      return
    }

    const hasChanged = currentPathname !== prevPathname

    if (!hasChanged) {
      return
    }

    onRouteChange(currentPathname)
  }, [
    currentPathname,
    prevPathname,
    ...deps
  ])
}

export default useOnRouteChange
