import { linearGradient } from 'polished'
import { css, keyframes } from 'styled-components'

export const FONT_SIZE = 16
export const LINE_HEIGHT = 1.6
export const FONT_FAMILY_PRIMARY = 'Inter, sans-serif'
export const FONT_FAMILY_SECONDARY = '"Open Sauce One", sans-serif'
export const FONT_FAMILY_ALT = '"Mortend", sans-serif'
export const FONT_FAMILY_ALT_OUTLINE = '"Mortend Outline", sans-serif'

export const GRAY_900 = '#0E0E0E'
export const GRAY_700 = '#1D1D1D'
export const GRAY_600 = '#5F5F5F'
export const GRAY_400 = '#A1A1A1'

export const COLOR_RED = '#FF4D42'
export const COLOR_BLUE = '#5546FF'
export const COLOR_GREEN = '#04B274'
export const COLOR_PURPLE = '#7A05D6'
export const COLOR_ORANGE = '#FF7E7E'
export const COLOR_OBJECTS = '#8E0AFF'

export const COLOR_SUCCESS = COLOR_GREEN
export const COLOR_INFO = COLOR_BLUE
export const COLOR_ERROR = COLOR_RED

export const COLOR_TEXT = 'white'

export const COLOR_PRIMARY = COLOR_BLUE

export const FONT_SIZE_XXL = '3rem'
export const FONT_SIZE_XL = '2.25rem'
export const FONT_SIZE_L = '1.5rem'
export const FONT_SIZE_M = '1.125rem'
export const FONT_SIZE_S = '0.875rem'

export const CONTAINER_WIDTH = 1284
export const MOBILE_SIDE_PADDING = 10

export const Z_PROFILE_IMAGE = 1000
export const Z_CONFETTI = 500
export const Z_POPUP_MENU = 9000
export const Z_VISUAL_BUILDER_REGION = 750
export const Z_VISUAL_BUILDER_SETTINGS = 9000
export const Z_MODAL_OVERLAY = 3000
export const Z_MODAL_DIALOG = 999999
export const Z_MODAL_TRANSACTION_VIEW = 9000

export const HEADER_BG_COLOR = GRAY_900
export const HEADER_HEIGHT_DESKTOP = 96
export const HEADER_HEIGHT_MOBILE = 90

export const HEADER_HEIGHT = 100

export const GRADIENT_PRIMARY = linearGradient({
  colorStops: ['#7A05D6 -7.67%', '#546EF9 100%'],
  toDirection: '250deg'
})

export const GRADIENT_SECONDARY = linearGradient({
  colorStops: ['#FFFFFF 2.56%', '#A4A4A4 95.9%'],
  toDirection: '266deg'
})

export const GRADIENT_ALT = linearGradient({
  colorStops: [`${COLOR_ORANGE} 5.97%`, '#9440FF 102.63%'],
  toDirection: '266deg'
})

export const GRADIENT_DARK = linearGradient({
  colorStops: [
    'rgba(255, 255, 255, 0.08) -6.31%',
    'rgba(20, 20, 20, 0) 68.29%'
  ],
  toDirection: '145deg',
  fallback: '#2E2E2E'
})

export const scrollbarStyling = css`
  ::-webkit-scrollbar
  {
    width: 8px;  /* for vertical scrollbars */
    height: 8px; /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track
  {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);
  }
`

export const keyframePulse = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
`
