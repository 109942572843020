import { GA_TRACKING_ID } from '../config'

export const pageView = (url) => {
  if (!window?.gtag) {
    return
  }

  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
    custom_map: {
      dimension2: 'gpu_tier',
      metric5: 'fps'
    }
  })
}

export const event = ({ action, category, label, value }) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.debug('Sending Google Analytics event', {
      action,
      category,
      label,
      value
    })
  }

  if (!GA_TRACKING_ID) {
    return
  }

  if (!window?.gtag) {
    return
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}

export const rawEvent = (action, params) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.debug('Sending raw Google Analytics event', params)
  }

  if (!GA_TRACKING_ID) {
    return
  }

  if (!window?.gtag) {
    return
  }

  window.gtag('event', action, params)
}
