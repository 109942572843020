import React from 'react'
import styled from 'styled-components'

import { COLOR_ERROR, COLOR_PRIMARY, COLOR_SUCCESS } from '../theme'

const VARIANT_DEFAULT = 'default'
const VARIANT_SUCCESS = 'success'
const VARIANT_WARNING = 'warn'
const VARIANT_ERROR = 'error'

const getBackgroundColor = props => {
  switch (props.$variant) {
    case VARIANT_SUCCESS:
      return COLOR_SUCCESS
    case VARIANT_WARNING:
      return COLOR_PRIMARY
    case VARIANT_ERROR:
      return COLOR_ERROR
  }
}

const Component = styled.div`
  padding: 1rem;
  background-color: ${props => getBackgroundColor(props)};
  border-radius: 4px;
`

const Title = styled.div`
  font-weight: 600;
  margin-bottom: .2rem;
`

const Content = styled.div`
`

const Message = ({
  children,
  title,
  variant = VARIANT_DEFAULT
}) => {
  return (
    <Component $variant={variant}>
      {title && (
        <Title>
          {title}
        </Title>
      )}
      <Content>
        {children}
      </Content>
    </Component>
  )
}

Message.VARIANT_DEFAULT = VARIANT_DEFAULT
Message.VARIANT_SUCCESS = VARIANT_SUCCESS
Message.VARIANT_WARNING = VARIANT_WARNING
Message.VARIANT_ERROR = VARIANT_ERROR

export default Message
