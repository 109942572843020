import { useField } from 'formik'
import React from 'react'
import styled, { css } from 'styled-components'

import { COLOR_ERROR, COLOR_PRIMARY } from '../../theme'

export const INPUT_BACKGROUND_COLOR = '#242424'
export const INPUT_BORDER_RADIUS = 4
export const INPUT_PADDING = '1.2rem'
export const INPUT_COLOR = 'white'
export const INPUT_PLACEHOLDER_COLOR = 'rgba(255, 255, 255, .3)'
export const INPUT_BOX_SHADOW = `inset 0 0 0 2px ${COLOR_PRIMARY}`
export const INPUT_BOX_SHADOW_ERROR = `inset 0 0 0 2px ${COLOR_ERROR}`

export const INPUT_BASE_CSS = css`
  border: 0;
  background-color: ${INPUT_BACKGROUND_COLOR};
  color: ${INPUT_COLOR};
  border-radius: ${INPUT_BORDER_RADIUS}px;
  width: 100%;
  outline: 0;
  padding: ${INPUT_PADDING};
  transition: box-shadow 80ms ease-in-out;

  ${props => props.$hasError && css`
    box-shadow: inset 0 0 0 2px ${COLOR_ERROR} !important;
  `}

  &:focus {
    box-shadow: inset 0 0 0 2px ${COLOR_PRIMARY};
  }

  &::placeholder {
    color: ${INPUT_PLACEHOLDER_COLOR};
  }
`

const Component = styled.input`
  ${INPUT_BASE_CSS}
`

const InputText = ({
  type = 'text',
  placeholder,
  formatter,
  maxLength,
  min,
  max,
  disabled,
  readOnly,
  onBlur,
  autoFocus,
  ...props
}) => {
  const [{
    onBlur: fieldOnBlur,
    value,
    name
  }, meta, { setValue }] = useField(props)
  const hasError = meta?.error && meta?.touched

  const handleChange = (e) => {
    const value = formatter
      ? formatter(e.target.value)
      : e.target.value

    setValue(value)
  }

  return (
    <Component
      readOnly={readOnly}
      autoFocus={autoFocus}
      $hasError={hasError}
      type={type}
      onChange={handleChange}
      onBlur={onBlur || fieldOnBlur}
      name={name}
      placeholder={placeholder}
      value={value}
      maxLength={maxLength}
      min={min}
      max={max}
      disabled={disabled}
    />
  )
}

export default InputText
