export function randomPoint (min: number, max: number) {
  const random = Math.abs(Math.random() * (max - min) + min)
  return parseInt(random.toFixed(0), 10)
}

export const loadImage = (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const image = new Image()

  image.onload = () => {
    resolve(image)
  }

  image.src = src

  image.onerror = () => {
    const error = new Error(`Image ${src} is not loaded.`)
    reject(error)
  }
})

export function dispatchCustomEvent (target: HTMLCanvasElement, type: string, detail: any) {
  const customEvent = new CustomEvent(type, {
    bubbles: true,
    cancelable: true,
    detail: detail
  })

  target.dispatchEvent(customEvent)
}

export function injectHTML (html: string, target: HTMLElement) {
  const parser = new DOMParser()
  const wrapper = document.createElement('div')
  wrapper.classList.add('sc__inner')
  const content = parser.parseFromString(html, 'text/html') // > IE 11
  wrapper.innerHTML = content.body.innerHTML
  target.insertBefore(wrapper, target.firstElementChild)
}

export function getOffset (element: HTMLElement) {
  const offset = {
    left: 0,
    top: 0
  }
  const clientRect = element.getBoundingClientRect()

  while (element) {
    offset.top += element.offsetTop
    offset.left += element.offsetLeft
    element = <HTMLElement>element.offsetParent
  }

  // Calculate the delta between offset values and clientRect values
  const deltaLeft = offset.left - clientRect.left
  const deltaTop = offset.top - clientRect.top

  return {
    left: (deltaLeft < 0) ? offset.left + Math.abs(deltaLeft) : offset.left - Math.abs(deltaLeft),
    top: (deltaTop < 0) ? offset.top + Math.abs(deltaTop) : offset.top - Math.abs(deltaTop)
  }
}
