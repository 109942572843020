import { useReactiveVar } from '@apollo/client'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled, { css } from 'styled-components'

import layoutVar from '../../apollo/vars/layout'
import { LEARN, PROJECTS, HOME, COLLECTIONS } from '../../constants/Routes'
import useSession from '../../hooks/useSession'
import { CONTAINER_WIDTH, MOBILE_SIDE_PADDING } from '../../theme'
import { optimizedLogoUri } from '../../utils/images'
import { MEDIA_QUERY_DESKTOP } from '../../utils/media-query'
import ButtonCreate from '../ButtonCreate'
import GradientText from '../GradientText'
import PageHeaderControlsDesktop from './PageHeaderControls.desktop'
import PageHeaderControlsMobile from './PageHeaderControls.mobile'

export const HEADER_HEIGHT = 100

const Component = styled.div`
  z-index: 99999;
  position: relative;
  height: ${HEADER_HEIGHT}px;
  will-change: transform, opacity;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;

  ${props => props.$isClosed && css`
    transform: translateY(-120px);
    opacity: 0;
  `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  max-width: ${CONTAINER_WIDTH}px;
  padding: 0 ${MOBILE_SIDE_PADDING}px;
  margin: 0 auto;
`

const LogoContainer = styled.div`
  line-height: 1;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const Links = styled.div`
  margin-right: 1rem;
  display: none;

  ${MEDIA_QUERY_DESKTOP} {
    display: flex;
  }
`

const ButtonCreateContainer = styled.div`
  margin-right: 2rem;
  display: none;

  ${MEDIA_QUERY_DESKTOP} {
    display: block;
  }

  a {
    &:hover {
      text-decoration: none !important;
    }
  }
`

const LinkWrapper = styled.div`
`

const LinkAnchor = styled.a`
  color: ${props => props.$isActive ? 'rgba(255, 255, 255, .9) !important' : 'rgba(255, 255, 255, .5)'};;
  font-weight: 600;
  padding: 1rem;
  display: inline-block;
  transition: 120ms color;

  &:hover,
  &:focus {
    text-decoration: none;
    color: rgba(255, 255, 255, .7);
  }
`

const LogoImage = styled.img``

const ObjectsLabel = styled.div`
  display: inline-flex;
  align-content: center;
`

const ObjectsLabelPrimary = styled.div``
const ObjectsLabelSecondary = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  transform: translate(3px) translateY(-3px);
  opacity: 1;
  letter-spacing: 1px;
`

const links = [{
  label: 'Objects',
  href: HOME
}, {
  label: (
    <ObjectsLabel>
      <ObjectsLabelPrimary>
        Collections
      </ObjectsLabelPrimary>
      <ObjectsLabelSecondary>
        <GradientText variant={GradientText.VARIANT_EPIC}>
          new
        </GradientText>
      </ObjectsLabelSecondary>
    </ObjectsLabel>
  ),
  href: COLLECTIONS
}, {
  label: 'Learn',
  href: LEARN
}]

const NewPageHeader = () => {
  const router = useRouter()
  const session = useSession()
  const layoutSettings = useReactiveVar(layoutVar.reactiveVar)
  const isClosed = layoutSettings.headerIsClosed

  return (
    <Component
      $isClosed={isClosed}
    >
      <Content>
        <LogoContainer>
          <Link href='/'>
            <a>
              <LogoImage
                src={optimizedLogoUri}
                width={67}
                height={67}
                quality={100}
                alt='Triptcip'
              />
            </a>
          </Link>
        </LogoContainer>
        <Controls>
          <Links>
            {links.map((link, index) => {
              const isActive = router.pathname === link.href

              return (
                <LinkWrapper key={index}>
                  <Link
                    href={link.href}
                    passHref
                  >
                    <LinkAnchor $isActive={isActive}>
                      {link.label}
                    </LinkAnchor>
                  </Link>
                </LinkWrapper>
              )
            })}
          </Links>
          {session.isLoggedIn && (
            <ButtonCreateContainer>
              <Link
                href={PROJECTS}
                passHref
              >
                <a>
                  <ButtonCreate />
                </a>
              </Link>
            </ButtonCreateContainer>
          )}
          <PageHeaderControlsDesktop />
          <PageHeaderControlsMobile />
        </Controls>
      </Content>
    </Component>
  )
}

export default NewPageHeader
