import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import { API_URL } from '../config'
import { getToken } from '../utils/token-store'

const uploadLink = createUploadLink({
  uri: API_URL
})

export const cache = new InMemoryCache({
  typePolicies: {
    TokenMetadata: {
      keyFields: false
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  const requestHeaders = {
    ...headers
  }

  if (token) {
    requestHeaders['x-token'] = token
  }

  return {
    headers: requestHeaders
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache
})

export default apolloClient
