import React from 'react'
import styled, { css } from 'styled-components'

import Message from './Message'

const Component = styled.div`
  perspective: 1500px;
  backface-visibility: hidden;
`

const Error = styled.div`
  ${props => {
    if (props.$hasErrorPadding) {
      return css`
        padding-top: 1rem
      `
    }
  }}
`

const Errors = ({
  errors,
  hasErrorPadding = true,
  getErrorMessage
}) => {
  if (!errors?.length > 0) {
    return null
  }

  return (
    <Component>
      {errors.map((error, index) => {
        return (
          <Error
            $hasErrorPadding={hasErrorPadding}
            variant={Message.VARIANT_ERROR}
            key={index}
          >
            {getErrorMessage(error)}
          </Error>
        )
      })}
    </Component>
  )
}

export default Errors
