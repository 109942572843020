import { config } from '@react-spring/web'
import React from 'react'

import Transition from './Transition'

const transitionOptions = {
  config: config.default,
  from: {
    opacity: 0,
    transform: 'translateY(20px)'
  },
  enter: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  leave: {
    opacity: 0,
    transform: 'translateY(20px)'
  }
}

const Toggle = ({ isOpen, children }) => {
  return (
    <Transition
      shouldAnimate={isOpen}
      options={transitionOptions}
    >
      {children}
    </Transition>
  )
}

export default Toggle
