export const getEthereumFiatPrice = async (baseCurrency = 'usd') => {
  try {
    const res = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?vs_currencies=${baseCurrency}&ids=ethereum`
    )

    const json = await res.json()
    return json.ethereum.usd

    // Ignore errors
  } catch (e) {
    return null
  }
}
