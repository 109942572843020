import { formatEther } from '@ethersproject/units'

import balanceVar from '../apollo/vars/balance'
import walletVar from '../apollo/vars/wallet'
import { isDefined } from './vars'

const POLLING_INTERVAL = 2000

let isPolling = false

export const initializeBalance = async (ethereum, account) => {
  const currentBalance = await getAccountBalance(ethereum, account)
  balanceVar(currentBalance)
  pollBalance()
}

export const getAccountBalance = async (ethereum, account) => {
  if (!account) {
    return
  }

  const balance = await ethereum.request({
    method: 'eth_getBalance',
    params: [account, 'latest']
  })

  return formatEther(balance)
}

const updateAccountBalance = async () => {
  const wallet = walletVar.reactiveVar()
  const { isConnected, ethereum, account } = wallet

  if (!isConnected || !ethereum || !account) {
    return false
  }

  const balance = await getAccountBalance(ethereum, account)
  balanceVar(balance)
}

const pollBalance = () => {
  if (isPolling) {
    return
  }

  isPolling = true

  const tick = () => {
    setTimeout(() => {
      updateAccountBalance().finally(() => {
        tick()
      })
    }, POLLING_INTERVAL)
  }

  tick()
}

export const getShortBalanceLabel = balance => {
  if (!isDefined(balance)) {
    return 'Unknown balance'
  }

  const shortBalance = balance.substring(0, 6)
  return `${shortBalance} ETH`
}
