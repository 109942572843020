import React from 'react'

import TransactionViewContext from './contexts/transaction-view-context'
import useTransactionViewContextProvider from './hooks/useTransactionViewContextProvider'
import TransactionView from './TransactionView'

const TransactionViewConsumer = ({ children }) => {
  const transactionViewContextValue = useTransactionViewContextProvider()

  return (
    <TransactionViewContext.Provider value={transactionViewContextValue}>
      <TransactionView />
      {children}
    </TransactionViewContext.Provider>
  )
}

export default TransactionViewConsumer
