import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { INPUT_BASE_CSS } from './InputText'

const Component = styled.textarea`
  ${INPUT_BASE_CSS};
  resize: none;
`

const Textarea = ({ ...props }) => {
  const [field, meta] = useField(props)
  const hasError = meta?.error && meta?.touched

  return (
    <Component
      $hasError={hasError}
      rows={6}
      {...field}
      {...props}
    />
  )
}

export default Textarea
