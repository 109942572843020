import styled from 'styled-components'

import { COLOR_ERROR, COLOR_SUCCESS } from '../theme'

const VARIANT_SUCCESS = 'success'
const VARIANT_WARN = 'warn'

const getBackgroundColor = props => {
  switch (props.variant) {
    case VARIANT_SUCCESS:
      return COLOR_SUCCESS
    case VARIANT_WARN:
      return COLOR_ERROR
  }
}

const StatusIndicator = styled.div`
  border-radius: 8px;
  width: 12px;
  height: 12px;
  background-color: ${props => getBackgroundColor(props)};
`

StatusIndicator.VARIANT_SUCCESS = VARIANT_SUCCESS
StatusIndicator.VARIANT_WARN = VARIANT_WARN

export default StatusIndicator
